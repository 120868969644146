// @import "./scss/nucleo/css/nucleo.css";
// @import "./scss/font-awesome/css/font-awesome.min.css";
// @import "./scss/custom.scss";

@import url("../node_modules/semantic-ui-css/semantic.min.css");
@import url("../node_modules/react-datepicker/dist/react-datepicker.css");
@import url("../node_modules/rsuite/dist/styles/rsuite-default.min.css");
@import url("../node_modules/card-react/lib/card.css");
@import url("../node_modules/react-pro-sidebar/dist/scss/styles.scss");
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");
@import url("../node_modules/react-image-gallery/styles/css/image-gallery.css");
@import url("../node_modules/survey-react/survey.min.css");
@import url("../node_modules/react-responsive-carousel/lib/styles/carousel.min.css");
@import url("../node_modules/react-slideshow-image//dist//styles.css");

@font-face {
  font-family: "Pretendard-Thin";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff")
    format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Light";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff")
    format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-ExtraBold";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff")
    format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Black";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff")
    format("woff");
  font-weight: 900;
  font-style: normal;
}

html,
body,
#root,
#root > div {
  height: 100%;
}

#root > #mobile-nav {
  height: auto;
}

:root {
  --font-weight-thin: 100;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-bold: 700;
  --font-weight-bolder: 900;

  --line-height-base: 28px;
  --line-height-small: 24px;
  --line-height-medium: 28px;
  --line-height-large: 32px;
  --line-height-h7-base: 20px;
  --line-height-h7-medium: 24px;
  --line-height-h6-base: 24px;
  --line-height-h6-medium: 28px;
  --line-height-h5-base: 28px;
  --line-height-h5-medium: 30px;
  --line-height-h4-medium: 34px;
  --line-height-h3-base: 36px;
  --line-height-h3-medium: 40px;
  --line-height-h3-large: 52px;
  --line-height-h2-base: 44px;
  --line-height-h2-medium: 50px;
  --line-height-h2-large: 55px;
  --line-height-h1-base: 40px;
  --line-height-h1-medium: 52px;
  --line-height-h1-large: 65px;

  --container-width-base: 276px;
  --container-width-extra-small: 324px;
  --container-width-small: 424px;
  --container-width-medium: 680px;
  --container-width-large: 868px;
  --container-width-extra-large: 1016px;
  --container-width-x-large: 1280px;

  --container-max-width: 40em;

  --space-tiny: 5px;
  --space-base: 10px;
  --space-line: 20px;
  --space-adjusted-line: 15px;
  --space-small: 30px;
  --space-medium: 60px;
  --space-large: 80px;
  --space-xl: 100px;
  --space-xxl: 150px;

  --space-navbar-height: 80px;

  --font-size-base: 16px;
  --font-size-tiny: 12px;
  --font-size-small: 14px;
  --font-size-medium: 18px;
  --font-size-large: 20px;
  --font-size-h7-base: 12px;
  --font-size-h7-medium: 14px;
  --font-size-h6-base: 14px;
  --font-size-h6-medium: 16px;
  --font-size-h5-base: 18px;
  --font-size-h5-medium: 20px;
  --font-size-h5-large: 24px;
  --font-size-h4-base: 20px;
  --font-size-h4-medium: 24px;
  --font-size-h4-large: 28px;
  --font-size-h3-base: 28px;
  --font-size-h3-medium: 32px;
  --font-size-h3-large: 44px;
  --font-size-h2-base: 32px;
  --font-size-h2-medium: 40px;
  --font-size-h2-large: 50px;
  --font-size-h1-base: 40px;
  --font-size-h1-medium: 50px;
  --font-size-h1-large: 60px;

  --color-cole-gray: #efeff4;
  --color-blue: #006aff;
  --color-lightBlue: #91b9f8;
  --color-ultraLightBlue: #b1cefa;
  --color-superUltraLightBlue: #dbe9ff;
  --color-primary: #1a283e;
  --color-lightPrimary: #838b96;
  --color-white: #fff;
  --color-ultraLightPrimary: #a8adb6;
  --color-lightYellow: #eeda95;
  --color-ultraLightYellow: #fefaef;
  --color-yellow: #f6d662;
  --color-gray: #d1d4d8;
  --color-ultraLightGray: #e3e5e8;
  --color-green: #009688;
  --color-lightGreen: #66c0b8;
  --color-ultraLightGreen: #c5e7e4;
  --color-paleGray: #838b96;
  --color-lightBlue: #89b4f8;
  --color-cole-primary: #1b3154;
  --color-cole-lightPrimary: #7b879c;
  --color-orange: #ff8b3d;
  --color-red: #e02020;
  --color-lightRed: #f3aaaa;
  --color-lightOrange: #ff8b3d61;
  --color-ultraLightOrange: #ffe8d8;
  --color-superUltraLightRed: #fce9e9;
  --color-superUltraLightOrange: #ffe8d8;
}

.h1 {
  font-size: var(--font-size-h1-base);
  line-height: var(--line-height-h1-base);
}
.h2 {
  font-size: var(--font-size-h2-base);
  line-height: var(--line-height-h2-base);
}
.h3 {
  font-size: var(--font-size-h3-base);
  line-height: var(--line-height-h3-base);
}
.h4 {
  font-size: var(--font-size-h4-base);
  line-height: var(--line-height-h4-base);
}
.h5 {
  font-size: var(--font-size-h5-base);
  line-height: var(--line-height-h5-base);
}
.h6 {
  font-size: var(--font-size-h6-base);
  line-height: var(--line-height-h6-base);
}

.h4-medium {
  font-size: var(--font-size-h4-medium);
  line-height: var(--line-height-h4-medium);
}

.h6-medium {
  font-size: var(--font-size-h6-medium);
  line-height: var(--line-height-h6-medium);
}

.h7 {
  font-size: var(--font-size-h7-base);
  line-height: var(--line-height-h7-base);
}

.caption {
  font-size: var(--font-size-tiny) !important;
}

.light {
  font-weight: var(--font-weight-light) !important;
}
.normal {
  font-weight: var(--font-weight-normal);
}
.bold {
  font-weight: var(--font-weight-bold) !important;
}
.bolder {
  font-weight: var(--font-weight-bolder);
}

header {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e !important;
}

body {
  margin: 0;
  width: 100%;
  color: #1a283e;
  overflow-x: hidden !important;
  font-family: "Pretendard-Regular", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  overflow: auto;
}

::-webkit-scrollbar {
  // display: none;
  // background: transparent;
}

::-webkit-scrollbar-track-piece {
}

::-webkit-scrollbar-thumb {
  // background-color: red !important;
  // width: 2px !important;
}

.full-screen {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  flex: 1;
}

.mobile-body {
  padding: 0 15px;
  padding-top: 100px;
  -ms-overflow-style: none;
}

#page-wrap {
  overflow: auto !important;
}

.mobile-top {
  position: fixed;
  top: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* custom */
a {
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
}

.ui.text.container {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
}

.ui.header {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
}

.ui.list .list > .item .header,
.ui.list > .item .header {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
}

.ui.list > .item:after,
.ui.list > .list > .item,
ol.ui.list > li:first-child:after,
ul.ui.list > li:first-child:after {
  content: "";
  display: none;
  height: 0;
  // clear: both;
  visibility: hidden;
}

.ui.menu {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
}

.ui.menu .item {
  color: #1a283e;
}

.ui.menu .active.item {
  color: #1a283e;
}

.preparing {
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 1000;
}

.preparing-text {
  color: white;
  text-align: center;
  z-index: 1001;
  position: absolute;
  font-size: 30px;
}

.ui.cards > .card > .content > .header,
.ui.card > .content > .header {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
}

.ui.items > .item > .content > .header {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
}

ui.statistics .statistic > .value,
.ui.statistic > .value {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
}

.ui.statistics .statistic > .label,
.ui.statistic > .label {
  color: #1a283e;
  font-family: "Pretendard-Regular", sans-serif;
}

.ui.accordion .title:not(.ui) {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e !important;
}

.ui.modal > .header {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
}

.ui.popup > .header {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
}

.ui.search {
  font-size: 1rem !important;
}

.ui.search > .results .result .title {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
}

.ui.search > .results > .message .header {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
}

.ui.category.search > .results .category > .name {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
}

/* text */

.text-header {
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-family: "Pretendard-Regular", sans-serif;
  line-height: 1.13;
  letter-spacing: 0.36px;
  color: #1a283e;
}

.text-sub-header {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-family: "Pretendard-Regular", sans-serif;
  line-height: 1.2;
  letter-spacing: 0.38px;
  color: #1a283e;
}

.text-title {
  font-size: 1.43em !important;
  font-weight: 900;
  line-height: 1.2 !important;
  font-family: "Pretendard-Regular", sans-serif;
  letter-spacing: 0.38px !important;
  color: #1a283e;
  &--discount {
    -webkit-text-decoration: line-through #e02020;
    text-decoration: line-through #e02020;
    font-weight: 200 !important;
  }
}
.text-title2 {
  font-size: 20px !important;
  line-height: 1.2 !important;
  font-family: "Pretendard-Regular", sans-serif;
  letter-spacing: 0.38px !important;
  color: #1a283e;
}

.text-sub-title {
  font-size: 18px;
  font-weight: bold;
  font-family: "Pretendard-Regular", sans-serif;
  line-height: 1.2;
  letter-spacing: 0.38px;
  color: #1a283e;
}

.text-14 {
  font-size: 14px;
  font-family: "Pretendard-Regular", sans-serif;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #1a283e;
}

.text-14-sub {
  font-size: 14px;
  line-height: 21px;
  font-family: "Pretendard-Regular", sans-serif;
  letter-spacing: -0.32px;
  opacity: 0.54;
  color: #1a283e;
}

.text-13 {
  font-size: 13px;
  font-family: "Pretendard-Regular", sans-serif;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #1a283e;
}

.text-12 {
  font-size: 13px;
  font-family: "Pretendard-Regular", sans-serif;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #1a283e;
}

.label {
  font-size: 16px;
  font-family: "Pretendard-Regular", sans-serif;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #1a283e;
}

.caption1 {
  font-size: 1em;
  font-family: "Pretendard-Regular", sans-serif;
  line-height: 16px;
  letter-spacing: 0px;
  color: #1a283e;
}

.caption3 {
  font-size: 12px;
  font-family: "Pretendard-Regular", sans-serif;
  line-height: 15px;
  letter-spacing: 0px;
  color: #1a283e;
}

.caption2 {
  font-size: 10px;
  font-family: "Pretendard-Regular", sans-serif;
  line-height: 15px;
  letter-spacing: 0px;
  color: #1a283e;
}

.desc {
  font-size: 14px;
  font-family: "Pretendard-Regular", sans-serif;
  color: var(--color-primary);
}

.limit-one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}

.limit-two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 2.6em;
  line-height: 1.3em;
}

.line-breaker {
  line-break: anywhere;
}

.white-space-nowrap {
  white-space: nowrap;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-0 {
  line-height: 0px;
}

.font-bold {
  font-weight: bold !important;
}

.font-300 {
  font-weight: 300;
}

.font-light {
  font-weight: 200;
}

.font-underline {
  text-decoration: underline;
}

.yellow-underline {
  border-bottom: 2px solid #f6d662;
  -webkit-text-decoration: "underline";
  text-decoration: "underline";
}

.blue-underline {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 2px solid var(--color-blue);
  -webkit-text-decoration: "underline";
  text-decoration: "underline";
}

.yellow-underline-bold {
  border-bottom: 4px solid #f6d662;
  -webkit-text-decoration: "underline";
  text-decoration: "underline";
}

.blue-underline-bold {
  border-bottom: 4px solid var(--color-blue);
  -webkit-text-decoration: "underline";
  text-decoration: "underline";
}

.textLines-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.opacity-54 {
  opacity: 0.54;
}

.text-strikethrough {
  text-decoration: line-through;
}

.opacity-87 {
  opacity: 0.87;
}

.error-text {
  color: #e02020 !important;
}

.font-size-12 {
  font-size: 12px;
}

/* Color */

.bg-color-primary {
  background-color: #efeff4 !important;
}

.bg-color-gray {
  background-color: #d1d4d8;
}

.bg-color-coleGray {
  background-color: #efeff4 !important;
}

.bg-color-white {
  background-color: #fff;
}

.bg-color-trueWhite {
  background-color: #fff;
}

.bg-color-blue {
  background-color: #337ef3;
}

.bg-none {
  background-color: none;
}

.bg-color-transparent {
  background-color: transparent !important;
}

.border-none {
  border: none !important;
}

.border-basic {
  border: solid 1px #d1d4d8;
}

.border-primary {
  border: solid 1px #1a283e;
}

.border-select {
  border: solid 1px rgba(34, 36, 38, 0.15);
}

.border-white {
  border: solid 0.5px #ffffff !important;
}

.border-radius {
  border-radius: 12px;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-top {
  border-top: 1px solid #d1d4d8;
}

.border-top-light-gray {
  border-top: 0.5px dashed var(--color-ultraLightPrimary);
}

.borderRadius-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.left-border {
  border-left: solid 1px #d1d4d8;
  padding-left: 24px;
}

.white-color {
  color: #fff !important;
}
.trueWhite-color {
  color: #ffffff;
}

.blue-color {
  color: #337ef3 !important;
}

.red-color {
  color: #e02020 !important;
}

.black-color {
  color: #1a283e !important;
}

.deep-black-color {
  color: #000 !important;
}

.primary-color {
  color: #1a283e;
}

.lightPrimary-color {
  color: var(--color-lightPrimary);
}

.yellow-color {
  color: #f6d662;
}

.green-color {
  color: #009688;
}

.brown-color {
  color: #a0693d;
}

.orange-color {
  color: #ff6600;
}

.pale-gray-bg {
  background-color: #efeff4;
}

.blue-background {
  background-color: #337ef3;
}

.white-bg {
  background-color: #fff;
}

.yellow-background {
  background-color: #f6d662;
}

.green-background {
  background-color: #009688;
}

.brown-background {
  background-color: #a0693d;
}

.background-color-blue {
  background: rgba(51, 126, 243, 0.2) !important;
}

.paleGray-color {
  color: #838b96 !important;
}

.light-gray-color {
  color: #c6c9cf !important;
}

.navy-color {
  color: #1a283e !important;
}

.gray-color {
  color: #d1d4d8 !important;
}

.row {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.ui.button {
  min-height: auto !important;
  font-family: "Pretendard-Regular", sans-serif;
}

.ui.basic.button:focus,
.ui.basic.buttons .button:focus {
  background-color: transparent !important;
}

.ui.basic.button:hover,
.ui.basic.buttons .button:hover {
  background-color: transparent !important;
}

.ui.basic.grey.button,
.ui.basic.grey.buttons .button {
  color: rgba(0, 0, 0, 0.3) !important;
}

.remove-border {
  border: none !important;
  background: none;
}

.ui.inverted.blue.buttons .button,
.ui.inverted.blue.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #337ef3 inset !important;
  color: #337ef3;
}

.ui.inverted.blue.buttons .button:hover,
.ui.inverted.blue.button:hover {
  background-color: #337ef3;
}

.ui.blue.buttons .button,
.ui.blue.button {
  background-color: #337ef3;
  color: #ffffff;
  text-shadow: none;
  background-image: none;
}

.ui.blue.buttons .button:hover,
.ui.blue.button:hover {
  background-color: #89b1f2;
  color: #ffffff;
  text-shadow: none;
}

.ui.blue.button:focus,
.ui.blue.buttons .button:focus {
  background-color: #337ef3;
  color: #fff;
  text-shadow: none;
}

.ui.basic.blue.buttons .button,
.ui.basic.blue.button {
  box-shadow: 0px 0px 0px 1px #337ef3 inset !important;
  color: #337ef3 !important;
}

.ui.basic.blue.buttons .button:hover,
.ui.basic.blue.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #89b1f2 inset !important;
  color: #89b1f2 !important;
}

.ui.inverted.red.buttons .button:hover,
.ui.inverted.red.button:hover {
  background-color: #e02020;
}

.ui.red.buttons .button,
.ui.red.button {
  background-color: #e02020;
  color: #ffffff;
  text-shadow: none;
  background-image: none;
}

.ui.red.buttons .button:hover,
.ui.red.button:hover {
  background-color: #f3aaaa;
  color: #ffffff;
  text-shadow: none;
}

.ui.basic.red.buttons .button,
.ui.basic.red.button {
  box-shadow: 0px 0px 0px 1px #e02020 inset !important;
  color: #e02020 !important;
}

.ui.basic.red.buttons .button:hover,
.ui.basic.red.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #f3aaaa inset !important;
  color: #f3aaaa !important;
}

.ui.basic.button:focus {
  color: rgba(0, 0, 0, 0.3) !important;
}

.ui.message {
  min-height: auto !important;
}

.ui.message .header {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
}

.ui.basic.button.tertiary {
  box-shadow: none !important;
}
.ui.basic.button.tertiary:active:hover {
  box-shadow: none !important;
  opacity: 0.54;
}

.ui.form input:not([type]):focus,
.ui.form input[type="date"]:focus,
.ui.form input[type="datetime-local"]:focus,
.ui.form input[type="email"]:focus,
.ui.form input[type="number"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="search"]:focus,
.ui.form input[type="tel"]:focus,
.ui.form input[type="time"]:focus,
.ui.form input[type="text"]:focus,
.ui.form input[type="file"]:focus,
.ui.form input[type="url"]:focus {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
  border-color: #337ef3;
  border-radius: 0.28571429rem;
  background: #ffffff;
  box-shadow: 0px 0em 0em 0em rgba(34, 36, 38, 0.35) inset;
}

.ui.form .fields.error .field textarea,
.ui.form .fields.error .field select,
.ui.form .fields.error .field input:not([type]),
.ui.form .fields.error .field input[type="date"],
.ui.form .fields.error .field input[type="datetime-local"],
.ui.form .fields.error .field input[type="email"],
.ui.form .fields.error .field input[type="number"],
.ui.form .fields.error .field input[type="password"],
.ui.form .fields.error .field input[type="search"],
.ui.form .fields.error .field input[type="tel"],
.ui.form .fields.error .field input[type="time"],
.ui.form .fields.error .field input[type="text"],
.ui.form .fields.error .field input[type="file"],
.ui.form .fields.error .field input[type="url"],
.ui.form .field.error textarea,
.ui.form .field.error select,
.ui.form .field.error input:not([type]),
.ui.form .field.error input[type="date"],
.ui.form .field.error input[type="datetime-local"],
.ui.form .field.error input[type="email"],
.ui.form .field.error input[type="number"],
.ui.form .field.error input[type="password"],
.ui.form .field.error input[type="search"],
.ui.form .field.error input[type="tel"],
.ui.form .field.error input[type="time"],
.ui.form .field.error input[type="text"],
.ui.form .field.error input[type="file"],
.ui.form .field.error input[type="url"] {
  background: #fff6f6;
  border-color: #e02020;
  color: rgba(0, 0, 0, 0.95);
  border-radius: "";
  box-shadow: none;
}

.ui.form .fields.error .field .ui.dropdown,
.ui.form .field.error .ui.dropdown {
  border-color: #e02020 !important;
}

.ui.form .fields.error .field .ui.multiple.selection.dropdown > .label,
.ui.form .field.error .ui.multiple.selection.dropdown > .label {
  background-color: #eacbcb;
  color: #e02020;
}

.ui.selection.list .list > .item,
.ui.selection.list > .item {
  cursor: pointer;
  background: transparent;
  padding: 1em 0.5em;
  margin: 0em;
  color: #1a283e;
  border-radius: 0.5em;
  transition: 0.1s color ease, 0.1s padding-left ease,
    0.1s background-color ease;
}
.ui.selection.list.list > .item:hover,
.ui.selection.list > .item:hover {
  background: rgba(51, 126, 243, 0.1);
  color: rgba(51, 126, 243, 1);
}

.ui-list-grid {
  display: list-item;
  table-layout: fixed;
  list-style-type: none;
  list-style-position: outside;
  padding: 10px 0px !important;
  line-height: 1.14285714em;
  border-top: 1px solid var(--color-gray);
  border-radius: 0px !important;
}

.ui-list-border {
  border-top: 1px solid var(--color-gray) !important;
  border-top-width: 1px !important;
  border-top-style: solid !important;
  border-top-color: var(--color-gray) !important;
}

.ui.tabular.menu .active.item {
  color: #1a283e;
}

.ui.vertical.tabular.menu .active.item {
  color: #1a283e;
}

.ui.tabular.menu .item {
  color: #1a283e;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  color: #1a283e;
}

.ui.dropdown .menu > .item {
  color: #1a283e;
}

.ui.selection.visible.dropdown > .text:not(.default) {
  color: #1a283e;
}

.ui.input > input {
  margin: 0em;
  max-width: 100%;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: "Pretendard-Regular", sans-serif;
  padding: 0.67857143em 1em;
  background: #ffffff;
  border: none;
  color: #1a283e;
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
}

.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="time"],
.ui.form input[type="text"],
.ui.form input[type="file"],
.ui.form input[type="url"] {
  font-family: "Pretendard-Regular", sans-serif;
  color: #1a283e;
}

.ui.input.error > input {
  background-color: #fff6f6 !important;
  font-family: "Pretendard-Regular", sans-serif;
  border-color: #e0b4b4 !important;
  color: #e02020 !important;
}

.ui.form textarea:focus {
  color: rgba(0, 0, 0, 0.95);
  border-color: #337ef3;
  border-radius: 0.28571429rem;
  background: #ffffff;
  box-shadow: 0px 0em 0em 0em rgba(34, 36, 38, 0.35) inset;
  -webkit-appearance: none;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #1a283e !important;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: #1a283e !important;
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  color: #337ef3;
}
.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before {
  border-color: #337ef3;
}
.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before {
  border-color: #337ef3;
}

.ui.checkbox input:checked:focus ~ .box:after,
.ui.checkbox input:checked:focus ~ label:after,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ .box:after,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ label:after {
  color: #337ef3;
}

.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ .box:before,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ label:before {
  border-color: #337ef3;
}

.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before {
  border-color: #337ef3;
}

.ui.checkbox .box:before,
.ui.checkbox label:before {
  border: solid 1px #337ef3;
  border-radius: 0.2rem;
}

.ui.checkbox .box:hover::before,
.ui.checkbox label:hover::before {
  border-color: #89b1f2;
}
.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  border-color: #337ef3;
}

.rotate {
  -webkit-animation: rotation 8s infinite linear;
  animation: rotation 8s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/* TODO: Login 페이지 Input박스 수정 */

/* .ui.input.input input {
  padding-right: 1em !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
} */

.ui.basic.button.tertiary:hover {
  box-shadow: none !important;
}

.ui.basic.button.tertiary:not(:hover) {
  box-shadow: none !important;
}

.ui.basic.table.border-none {
  border: none !important;
}

.ui.basic.button.bold {
  font-weight: bold !important;
}

.ui.labeled.icon.buttons > .button > .icon,
.ui.labeled.icon.button > .icon {
  position: absolute;
  height: 100%;
  line-height: 1;
  border-radius: 0px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  text-align: center;
  margin: 0em;
  width: 2.57142857em;
  color: "";
  box-shadow: -1px 0px 0px 0px transparent inset;
}

.ui.basic.blue.label {
  background: none #fff !important;
  color: #337ef3 !important;
  border-color: #337ef3 !important;
}

i.blue.icon {
  color: #337ef3 !important;
}

.ui.inverted.menu {
  background: #1a283e;
}

/* REACT-DATE_RANGE_PICKER */

.rs-picker-default .rs-picker-toggle,
.rs-picker-default .rs-picker-toggle-custom {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  color: red;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  background: #fff;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: #337ef3 !important;
}

.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled,
.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled:hover,
.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled:hover:active {
  background-color: #337ef3 !important;
}
.rs-picker-toolbar-right-btn-ok {
  background-color: #337ef3 !important;
  border-radius: 0.28571429rem;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border: 1px solid #337ef3 !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #fff;
  border-color: #337ef3 !important;
  background-color: #337ef3 !important;
}

.rs-calendar-table-cell-in-range:before {
  background-color: #337ef3 !important;
  opacity: 0.34;
}

.rs-picker-toolbar-option {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 8px 12px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 0.28571429rem;
  border: none;
  color: #337ef3 !important;
  font-weight: 400;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.rs-picker-toggle {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 0.28571429rem !important;
}

.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #1a283e !important;
}

.rs-picker-date .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle-clean {
  right: 35px;
}

.rs-picker-date.rs-picker-default .rs-picker-toggle-caret,
.rs-picker-date.rs-picker-default .rs-picker-toggle-clean,
.rs-picker-daterange.rs-picker-default .rs-picker-toggle-caret,
.rs-picker-daterange.rs-picker-default .rs-picker-toggle-clean {
  top: 7px;
}

/* REACT-DATEPICKER */

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #fff !important;
  font-weight: bold;
  font-size: 16px !important;
}

.react-datepicker {
  font-family: "Pretendard-Regular", sans-serif;
  font-size: 1rem !important;
  background-color: #fff;
  color: #1a283e;
  border: none !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
}

.react-datepicker__header {
  text-align: center;
  background-color: #337ef3 !important;
  border-bottom: 1px solid #fff !important;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #fff !important;
  width: 70px;
}

.react-datepicker__day-name,
.react-datepicker__time-name {
  color: #fff !important;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #337ef3 !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #337ef3 !important;
  color: white;
  font-weight: bold;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  border-radius: 0.3rem;
  background-color: #337ef3 !important;
  color: #fff;
}

/* margin configuration */

.mt-70 {
  margin-top: 5rem !important;
}
.mt-55 {
  margin-top: 4rem !important;
}

.m-at {
  margin: auto;
}
.m-lg {
  margin: 40px;
}

.mr-lg {
  margin-right: 40px !important;
}
.mt-lg {
  margin-top: 40px !important;
}

.mb-lg {
  margin-bottom: 40px !important;
}
.ml-lg {
  margin-left: 40px !important;
}
.ml-0 {
  margin-left: 0px !important;
}

.ml-auto {
  margin-left: auto;
}

.ml-mr-lg {
  margin-left: 40px;
  margin-right: 40px;
}

.m-md {
  margin: 24px !important;
}

.mt-mb-45 {
  margin-top: 3.2em;
  margin-bottom: 3.2em;
}

.mt-mb-lg {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mt-mb-md {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.mt-md {
  margin-top: 24px !important;
}
.mb-md {
  margin-bottom: 24px !important;
}

.ml-md {
  margin-left: 24px !important;
}

.mt-mr-md {
  margin-top: 24px !important;
  margin-right: 24px !important;
}

.mr-md {
  margin-right: 24px !important;
}

.ml-mr-md {
  margin-left: 24px;
  margin-right: 24px;
}

.mt-mb-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.ml-mr-25 {
  margin-left: 25%;
  margin-right: 25%;
}

.ml-mr-sm {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mt-sm {
  margin-top: 15px !important;
}

.mr-sm {
  margin-right: 15px !important;
}

.mb-sm {
  margin-bottom: 15px !important;
}

.ml-sm {
  margin-left: 15px !important;
}

.mb-xs {
  margin-bottom: 10px !important;
}

.mt-xs {
  margin-top: 10px !important;
}

.mr-xs {
  margin-right: 10px !important;
}

.ml-xs {
  margin-left: 10px !important;
}

.mt-10-em {
  margin-top: 10em !important;
}

.mt-mb-xs {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.ml-mr-xs {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.ml-mr-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.ml-mr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-xs {
  margin-left: 10px !important;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-mb-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mt-minus-xs {
  margin-top: -10px !important;
}

.ml-mr-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mt-15 {
  margin-top: 5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mt-mb-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mb-minus-64px {
  margin-bottom: -64px !important;
}

/* padding configuration */

.p-right-100 {
  padding-right: 100px;
}

.p-right-50 {
  padding-right: 50px;
}

.p-zero {
  padding: 0px !important;
}

.p-lg {
  padding: 40px !important;
}

.p-md {
  padding: 24px !important;
}

.p-sm {
  padding: 0.94em !important;
}

.pt-lg {
  padding-top: 40px !important;
}
.pt-pb-lg {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pb-sm {
  padding-bottom: 0.94em !important;
}

.pb-100 {
  padding-bottom: 100px;
}
.pb-lg {
  padding-bottom: 40px !important;
}

.pt-md {
  padding-top: 24px !important;
}

.pt-xs {
  padding-top: 10px;
}

.pr-50 {
  padding-right: 120px !important;
}

.pl-lg {
  padding-left: 2.86em;
}

.pl-md {
  padding-left: 1.7em !important;
}
.pl-xs {
  padding-left: 10px;
}

.p-md {
  padding: 24px;
}

.p-xs {
  padding: 10px !important;
}

.p-8 {
  padding: 0.57em !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-md {
  padding-bottom: 24px !important;
}

.pt-pb-md {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.pt-pb-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pl-pr-lg {
  padding-left: 2.85em !important;
  padding-right: 2.85em !important;
}

.pl-pr-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.pl-pr-md {
  padding-left: 1.7rem !important;
  padding-right: 1.7rem !important;
}

.pl-pr-sm {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pl-pr-xs {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pr-xs {
  padding-right: 10px !important;
}

.pr-sm {
  padding-right: 15px !important;
}

.pr-md {
  padding-right: 24px !important;
}

.pt-sm {
  padding-top: 15px;
}

.pb-sm {
  padding-bottom: 15px !important;
}

.ph-lg {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.pl-sm {
  padding-left: 15px !important;
}

.pt-pb-xs {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pb-xs {
  padding-bottom: 10px;
}

.pl-pr-7 {
  padding-left: 7px;
  padding-right: 7px;
}

.pl-pr-5 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.pt-pb-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.p-0 {
  padding: 0 !important;
}

.pl-pr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pt-pb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pb-5 {
  padding-bottom: 5px;
}

.pt-3 {
  padding-top: 3px;
}

.pr-0 {
  padding-right: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pt-0 {
  padding-top: 0px !important;
}

.accordian-padding {
  padding-top: 13px;
  padding-right: 16px;
  padding-bottom: 13px;
  padding-left: 16px;
  vertical-align: middle;
}

// Margin & Padding
.m-medium {
  margin: var(--space-medium);
}

.m-base {
  margin: var(--space-base);
}

.mb-large {
  margin-bottom: var(--space-large);
}

.mb-medium {
  margin-bottom: var(--space-medium) !important;
}

.mb-small {
  margin-bottom: var(--space-small);
}

.mb-line {
  margin-bottom: var(--space-line);
}

.mb-adjusted {
  margin-bottom: var(--space-adjusted-line);
}

.mb-base {
  margin-bottom: var(--space-base);
}

.mt-large {
  margin-top: var(--space-large);
}

.mt-medium {
  margin-top: var(--space-medium);
}

.mt-mb-base {
  margin-top: var(--space-base);
  margin-bottom: var(--space-base);
}

.mt-mb-line {
  margin-top: var(--space-line);
  margin-bottom: var(--space-line);
}

.mt-mb-small {
  margin-top: var(--space-small);
  margin-bottom: var(--space-small);
}

.mt-mb-adjusted {
  margin-top: var(--space-adjusted-line);
  margin-bottom: var(--space-adjusted-line);
}

.mt-mb-medium {
  margin-top: var(--space-medium);
  margin-bottom: var(--space-medium);
}

.mt-small {
  margin-top: var(--space-small) !important;
}

.mt-line {
  margin-top: var(--space-line) !important;
}

.ml-tiny {
  margin-left: var(--space-tiny);
}

.ml-adjusted {
  margin-left: var(--space-adjusted-line);
}
.mr-adjusted {
  margin-right: var(--space-adjusted-line);
}
.mt-adjusted {
  margin-top: var(--space-adjusted-line);
}

.mt-auto {
  margin-top: auto;
}

.mt-tiny {
  margin-top: var(--space-tiny);
}

.mr-tiny {
  margin-right: var(--space-tiny);
}

.mt-base {
  margin-top: var(--space-base);
}

.ml-mr-base {
  margin-left: var(--space-base);
  margin-right: var(--space-base);
}

.ml-mr-adjusted {
  margin-left: var(--space-adjusted-line);
  margin-right: var(--space-adjusted-line);
}

.pt-pb-large {
  padding-top: var(--space-large);
  padding-bottom: var(--space-large);
}

.pt-pb-medium {
  padding-top: var(--space-medium);
  padding-bottom: var(--space-medium);
}

.pt-pb-small {
  padding-top: var(--space-small);
  padding-bottom: var(--space-small);
}

.pt-pb-line {
  padding-top: var(--space-line);
  padding-bottom: var(--space-line);
}

.pt-pb-adjusted {
  padding-top: var(--space-adjusted-line);
  padding-bottom: var(--space-adjusted-line);
}

.pt-pb-base {
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
}

.pt-pb-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pt-pb-tiny {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pt-medium {
  padding-top: var(--space-medium);
}

.pt-small {
  padding-top: var(--space-small) !important;
}

.pt-adjusted {
  padding-top: var(--space-adjusted-line);
}

.pt-line {
  padding-top: var(--space-line);
}

.pb-adjusted {
  padding-bottom: var(--space-adjusted-line);
}

.pb-base {
  padding-bottom: var(--space-base);
}

.pb-medium {
  padding-bottom: var(--space-medium);
}

.pr-base {
  padding-right: var(--space-base);
}

.pl-line {
  padding-left: var(--space-line);
}

.pl-small {
  padding-left: var(--space-small);
}

.pr-small {
  padding-right: var(--space-small);
}

.pl-pr-medium {
  padding-right: var(--space-medium) !important;
  padding-left: var(--space-medium) !important;
}

.pl-pr-small {
  padding-right: var(--space-small) !important;
  padding-left: var(--space-small) !important;
}

.pl-pr-adjusted {
  padding-right: var(--space-adjusted-line) !important;
  padding-left: var(--space-adjusted-line) !important;
}

.pl-pr-line {
  padding-right: var(--space-line) !important;
  padding-left: var(--space-line) !important;
}

.pl-pr-base {
  padding-right: var(--space-base) !important;
  padding-left: var(--space-base) !important;
}

.pl-pr-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.pb-small {
  padding-bottom: var(--space-small);
}

.pb-line {
  padding-bottom: var(--space-line);
}

.pr-line {
  padding-right: var(--space-line);
}

.pl-line {
  padding-left: var(--space-line);
}

.pl-base {
  padding-left: var(--space-base);
}

.ml-5 {
  margin-left: 5px;
}

.ml-base {
  margin-left: var(--space-base);
}

.ml-adjusted {
  margin-left: var(--space-adjusted-line);
}

.ml-line {
  margin-left: var(--space-line);
}

.ml-small {
  margin-left: var(--space-small);
}

.ml-medium {
  margin-left: var(--space-medium);
}

.mr-base {
  margin-right: var(--space-base);
}

.mr-line {
  margin-right: var(--space-line);
}

.mr-small {
  margin-right: var(--space-small);
}

.mr-medium {
  margin-right: var(--space-medium);
}

.ml-mr-auto {
  margin-right: auto;
  margin-left: auto;
}

.ml-mr-line {
  margin-right: var(--space-line);
  margin-left: var(--space-line);
}

.ml-mr-base {
  margin-right: var(--space-base);
  margin-left: var(--space-base);
}

.p-large {
  padding: var(--space-large);
}

.p-medium {
  padding: var(--space-medium) !important;
}

.p-line {
  padding: var(--space-line);
}

.p-adjusted {
  padding: var(--space-adjusted-line);
}

.p-base {
  padding: var(--space-base);
}

.p-tiny {
  padding: var(--space-tiny);
}

.p-small {
  padding: var(--space-small) !important;
}

//* display configuration */

.visibility-hidden {
  visibility: hidden !important;
}

.display-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.d-i-grid {
  display: inline-grid !important;
}

.d-i-block {
  display: inline-block;
}

.d-i-flex {
  display: inline-flex;
}

.f-d-row {
  flex-direction: row !important;
}

.f-d-column {
  flex-direction: column;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.d-flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.visible.transaction.d-flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.d-flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.f-1 {
  flex: 1;
}

.visibility-hidden {
  visibility: hidden;
}

.position-fixed {
  position: fixed;
}

/* align */

.justify-space-between {
  justify-content: space-between !important;
}
.justify-space-around {
  justify-content: space-around;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-items-end {
  justify-items: self-end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-end {
  justify-self: end;
}

.justify-c-f-end {
  justify-content: flex-end !important;
}

.justify-c-f-start {
  justify-content: flex-start !important;
}

.content-align-center {
  align-content: center;
}

.text-align-left {
  text-align: left !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right !important;
}

.text-align-end {
  text-align: end;
}

.vertical-align-center {
  vertical-align: center !important;
}

.word-break-all {
  word-break: break-all;
}

.align-items-flex-start {
  align-items: flex-start;
  display: flex;
}

.align-items-flex-end {
  align-items: flex-end;
  display: flex;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-self-start {
  align-items: self-start;
}

.align-items-self-end {
  align-items: self-end;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: end !important;
}

.align-items-f-end {
  align-items: flex-end !important;
}

.align-items-f-start {
  align-items: flex-start !important;
}

.align-items-stretch {
  align-items: stretch;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-center {
  align-self: center;
}

.align-self-f-start {
  align-self: flex-start;
}

.align-self-f-end {
  align-self: flex-end;
}

.vertical-bottom {
  vertical-align: bottom !important;
}

.vertical-super {
  vertical-align: super !important;
}

.force-center {
  position: absolute !important;
  left: 50%;
  right: 50%;
}

.force-right-margin {
  display: flex !important;
  margin-left: auto !important;
}

.force-right {
  position: absolute;
  right: 0;
}

.force-bottom {
  display: flex !important;
  margin-top: auto !important;
}

.side-footer-bottom {
  flex-shrink: 0;
  // padding: 3.6em 1em;
  padding: 1em;
  max-height: 171px;
  overflow-y: hidden;
}

.column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vertical-horizontal-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.side-modal-bg {
  position: absolute;
  top: 4.8em;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: #fff;
  overflow-y: auto;
}
.side-modal-header {
  background-color: #fff;
  display: flex;
  padding: 12px 10px;
  height: 4.8em;
  border-bottom: 1px solid #1a283e;
}

.cursor-pointer {
  cursor: pointer;
}

/* menu configuration */

.side-menu {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.side-body {
  flex: 1 0 auto;
}

.width-auto {
  min-width: auto !important;
}

.width-fit {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.w-h-100 {
  width: 100%;
  height: 100%;
}

.w-100 {
  width: 100% !important;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.h-100 {
  height: 100% !important;
}

.h-80 {
  height: 80%;
}

.h-60 {
  height: 60%;
}

.h-0 {
  height: 0 !important;
}

.height-450px {
  height: 450px;
}

.height-350px {
  height: 350px !important;
}

.width-100 {
  width: 100% !important;
}

.width-90 {
  width: 90% !important;
}

.width-85 {
  width: 85% !important;
}
.width-80 {
  width: 80%;
}

.width-75 {
  width: 75% !important;
}
.width-70 {
  width: 70% !important;
}
.width-66 {
  width: 66%;
}

.width-68 {
  width: 68%;
}
.width-65 {
  width: 65% !important;
}
.width-63 {
  width: 63% !important;
}
.width-60 {
  width: 60% !important;
}

.width-50 {
  width: 50% !important;
}
.width-45 {
  width: 45%;
}
.width-40 {
  width: 40% !important;
}
.width-35 {
  width: 35% !important;
}
.width-33 {
  width: 33%;
}
.width-30 {
  width: 30%;
}
.width-25 {
  width: 25% !important;
}
.width-20 {
  width: 20% !important;
}
.width-15 {
  width: 15%;
}
.width-10 {
  width: 10%;
}

.width-26 {
  width: 26% !important;
}
.width-30px {
  width: 30px !important;
}
.width-35px {
  width: 35px !important;
}
.width-40px {
  width: 40px !important;
}
.width-height-50px {
  width: 50px;
  height: 50px;
}
.width-height-120px {
  width: 114px;
  height: 114px;
}

.width-161px {
  width: 161px !important;
}

.width-120px {
  width: 120px !important;
}

.width-350px {
  width: 350px !important;
}
.width-200px {
  width: 200px !important;
}

.min-width-50 {
  min-width: 50% !important;
}

.min-width-auto {
  min-width: auto !important;
}

.min-width-50px {
  min-width: 50px !important;
}

.min-width-80px {
  min-width: 80px !important;
}

.min-width-100px {
  min-width: 100px !important;
}

.min-width-150px {
  min-width: 150px !important;
}

.min-width-300px {
  min-width: 300px !important;
}

.min-width-250px {
  min-width: 250px !important;
}

.min-width-450px {
  min-width: 450px !important;
}

.min-width-500px {
  min-width: 500px !important;
}

.min-width-650px {
  min-width: 650px !important;
}

.min-width-750px {
  min-width: 750px !important;
}

.max-width-75px {
  max-width: 75px;
}

.max-width-100px {
  max-width: 100px;
}

.max-width-130px {
  max-width: 150px !important;
}

.max-width-300px {
  max-width: 300px;
}

.max-width-350px {
  max-width: 350px !important;
}

.max-width-380px {
  max-width: 380px !important;
}

.max-width-400px {
  max-width: 400px;
}
.max-width-480px {
  max-width: 480px;
}

.max-width-550px {
  max-width: 550px !important;
}

.max-width-650px {
  max-width: 650px !important;
}
.max-width-700px {
  max-width: 700px !important;
}

.max-width-850px {
  max-width: 850px !important;
}

.max-width-1200px {
  max-width: 1200px !important;
}
.max-width-1680px {
  max-width: 1680px !important;
}

.min-height-200px {
  min-height: 200px !important;
}

.min-height-300px {
  min-height: 300px !important;
}

.min-height-450px {
  min-height: 450px !important;
}

.max-height-530px {
  max-height: 530px !important;
}

.max-height-120px {
  max-height: 120px;
}

.min-height-0 {
  min-height: 0px !important;
}

.min-height-60px {
  min-height: 60px !important;
}

.min-height-40px {
  min-height: 40px;
}

.min-height-30px {
  min-height: 30px !important;
}

.min-height-250px {
  min-height: 250px !important;
}

.min-height-350px {
  min-height: 350px !important;
}

.min-height-450px {
  min-height: 350px !important;
}

.min-height-150px {
  min-height: 150px !important;
}

.min-height-100px {
  min-height: 100px !important;
}

.min-height-70vh {
  min-height: 70vh !important;
}

.height-auto {
  height: auto !important;
}

.height-100 {
  height: 100%;
}

.height-80 {
  height: 80% !important;
}
.height-70 {
  height: 70% !important;
}
.height-90 {
  height: 90%;
}

.height-50 {
  height: 50% !important;
}
.height-60 {
  height: 60%;
}
.height-20 {
  height: 20% !important;
}
.height-10 {
  height: 10% !important;
}

.height-180px {
  height: 180px;
}

.height-38px {
  height: 38px;
}

.height-20px {
  height: 20px;
}

/* shadow */
.drop-down-shadow {
  box-shadow: 0 0.5px 3px 0.5px rgba(0, 0, 0, 0.1) !important;
}

.box-shadow {
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.1);
}

.nav-shadow {
  box-shadow: 0 0.5px 2px 1px #c6c9cf;
}

.bar-shadow {
  box-shadow: inset 0 1px 3px 0.5px rgba(0, 0, 0, 0.1) !important;
  border: solid 1px #d1d4d8 !important;
  border-radius: 5px !important;
}

/* dropdown */

.ui.selection.active.dropdown {
  border-color: #d1d4d8;
}
.ui.selection.active.dropdown .menu {
  border-color: #d1d4d8;
}
.ui.selection.dropdown:focus {
  border-color: #d1d4d8;
}
.ui.selection.dropdown:focus .menu {
  border-color: #d1d4d8;
}
.ui.selection.active.dropdown:hover {
  border-color: #d1d4d8;
}
.ui.selection.active.dropdown:hover .menu {
  border-color: #d1d4d8;
}
// .item:hover,
// .active.selected.item {
//   opacity: 0.6;
// }

/* View */

.ui.container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.dashboard-header {
  background-color: #f9f9f9;
  width: 100% !important;
  max-height: auto;
  border-radius: 2px;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.1);
  border: solid 0.5px #d1d4d8;
}
.dashboard-tile {
  width: 100% !important;
  // max-width: 1200px !important;
  min-height: 100px;
  border-radius: 2px;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.1);
  border: solid 0.5px #d1d4d8;
  background-color: #fff;
}
.excel-tile {
  width: 100% !important;
  max-height: 1000px;
  overflow-y: hidden;
  overflow-y: scroll;
  border-radius: 2px;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.1);
  border: solid 0.5px #d1d4d8;
  background-color: #f9f9f9;
}

.home-tile {
  width: 100% !important;
  min-height: 185px;
  border-radius: 2px;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.1);
  border: solid 0.5px #d1d4d8;
  background-color: #f9f9f9;
}

.survey-tile {
  width: 60% !important;
  height: 70%;
  border-radius: 5px;
  border: 1px solid #d1d4d8;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.report-tile {
  background-color: #ffffff;
  border: 1px solid #d1d4d8;
  border-radius: 5px;
  padding: 15px 24px;
}

.editor-tile {
  background-color: #ffffff;
  border-top: 1px solid #d1d4d8;
  border-bottom: 1px solid #d1d4d8;
  padding: 24px 40px;
  max-width: 1280px;
}

.excel-result-comp {
  background-color: #efeff4;
  display: flex;
  justify-content: space-between;
  padding: 2rem 1rem;
  width: 50%;
  margin-top: 2rem;
}

.excel-mode-container {
  background-color: #ffffff;
  border: 1px solid #d1d4d8;
  border-radius: 5px;
  padding: 15px 24px;
}

.loyalty-init-wrapper {
  background-color: #fff;
  border-radius: 9px;
  border: solid 0.5px var(--color-gray);
  padding: var(--space-line);
  margin-top: var(--space-line);
}

.loyalty-editor-header-wrapper {
  background-color: #fff;
}

.loyalty-header {
  background-color: #efeff4;
  display: flex;
  flex-direction: row;
  min-height: 50px;
}

.loyalty-subheader {
  padding: var(--space-base) var(--space-adjusted-line);
}
.loyalty-subheader:not(:first-child) {
  margin-left: var(--space-line);
}
.loyalty-review-comp {
  background-color: #fff;
  border-radius: 5px;
  margin-top: var(--space-base);
  padding: var(--space-base);
}

.loyalty-review-container {
  max-width: 780px;
  padding-bottom: var(--space-medium);
  margin-left: var(--space-small);
}

.loyalty-extra-description-wrapper {
  background-color: var(--color-ultraLightBlue);
  font-weight: var(--font-weight-bold);
  border-radius: 9px;
  padding: var(--space-line);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.picker-tile {
  width: 100%;
  min-height: 40px;
  border-radius: 5px;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.1);
  border: solid 0.5px #d1d4d8;
  background-color: #f9f9f9;
  text-align: center;
}

.review-template-tile {
  background-color: #fff;
  border: 1px solid #d1d4d8;
  padding: 1.5em 2em;
  // height: 100%;
}

.campaign-block {
  border: 3px solid #1a283e;
  border-radius: 9px;
  padding: 1.5em;
  background-color: #fff;
}

.inactive-status {
  background-color: #d1d4d8;
  border-radius: 12px;
  padding: 5px 10px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: bold;
}

.active-status {
  background-color: #009688;
  border-radius: 12px;
  padding: 5px 10px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: bold;
}

.app-header {
  // background-image: url("./img/graphic/app-header-bg.png");
  // background-position: center;
  // background-repeat: no-repeat;
  min-height: 15em;
  background-color: #fff;
  border-radius: 5px;
  background-size: cover;
  padding: 2.5em 2.4em;
  text-align: center;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.3);
}

.app-tile {
  border-radius: 5px;
  background-color: #fff;
  padding: 1.7em;
  cursor: pointer;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  min-height: 14em;
}
.app-tile:hover {
  opacity: 0.38;
}

.app-tile-disabled {
  border-radius: 5px;
  background-color: #fff;
  padding: 1.7em;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  min-height: 14em;
  opacity: 0.38;
}

.app-body {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  min-height: 20em;
  padding-bottom: 2.85em;
}
.app-label {
  border-bottom: 2px solid #1a283e;
  padding: 10px 24px 10px 50px;
  // border-radius: 0px 5px 5px 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 30px;
}

.app-icon {
  max-width: 105px;
  min-width: 65px;
  width: 100%;
}

#primary-button {
  background-color: #1a283e;
  color: #fff;
}
#primary-button:hover {
  background-color: #c6c9cf;
}

.primary-button {
  background-color: #1a283e !important;
  color: #fff !important;
  &--bottom {
    border-radius: 0 0 0.28571429rem 0.28571429rem !important;
  }
  &--windowBottom {
    border-radius: 0 !important;
    box-shadow: 0 -1px 3px 1px rgba(0, 0, 0, 0.3) !important;
  }
  &--force-height {
    height: 53px !important;
  }
}
.primary-button:hover {
  background-color: #c6c9cf !important;
}

.primary-outline-button {
  background-color: #fff !important;
  color: #1a283e !important;
  border: 1px solid #1a283e !important;
  &--bottom {
    border-radius: 0 0 0.28571429rem 0.28571429rem !important;
  }
}

.primary-outline-button.Mui-selected {
  background-color: #c6c9cf !important;
  color: #1a283e !important;
  border: 1px solid #1a283e !important;
  &--bottom {
    border-radius: 0 0 0.28571429rem 0.28571429rem !important;
  }
}

.primary-outline-button:hover {
  opacity: 0.38;
}

.primary-nofill-button {
  background-color: transparent !important;
  color: #1a283e !important;
  &--bottom {
    border-radius: 0 0 0.28571429rem 0.28571429rem !important;
    border-top: 1px solid #1a283e !important;
  }
  &--body {
    border-radius: 0 !important;
  }
}
.primary-nofill-button:hover {
  opacity: 0.38;
}

.blue-nofill-button {
  background-color: transparent !important;
  color: #337ef3 !important;
  margin-right: 0 !important;
}
.blue-nofill-button:hover {
  opacity: 0.38;
}
.primary-nofill-button:hover {
  opacity: 0.38;
}
.red-nofill-button {
  background-color: transparent !important;
  color: #e02020 !important;
}
.red-nofill-button:hover {
  opacity: 0.38;
}

// NEW BUTTONS

// Button

.primary-element-button {
  background-color: var(--color-primary);
  font-size: var(--font-size-h6-base);
  border: none;
  color: #fff;
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.primary-element-button:hover {
  background-color: var(--color-lightPrimary);
}

.primary-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.primary-nofill-element-button {
  border: 0px;
  background-color: transparent;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary);
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.primary-nofill-element-button:hover {
  color: var(--color-lightPrimary);
}

.primary-nofill-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.primary-outline-element-button {
  background-color: #fff;
  border: 2px solid var(--color-primary);
  font-size: var(--font-size-h6-base);
  color: var(--color-primary);
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.primary-outline-element-button:hover {
  background-color: var(--color-cole-gray);
}

.primary-outline-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.blue-element-button {
  border: 0px;
  background-color: var(--color-blue);
  font-size: var(--font-size-h6-base);
  color: #fff;
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.blue-element-button:hover {
  background-color: var(--color-lightBlue);
}

.blue-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.lightBlue-element-button {
  border: 0px;
  background-color: var(--color-ultraLightBlue);
  font-size: var(--font-size-h6-base);
  color: var(--color-blue);
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.lightBlue-element-button:hover {
  background-color: var(--color-gray);
}

.lightBlue-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.ultraLightBlue-element-button {
  border: 0px;
  background-color: var(--color-superUltraLightBlue);
  font-size: var(--font-size-h6-base);
  color: var(--color-blue);
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ultraLightBlue-element-button:hover {
  background-color: var(--color-cole-gray);
}

.ultraLightBlue-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.blue-outline-element-button {
  background-color: #fff;
  border: 2px solid var(--color-blue);
  font-size: var(--font-size-h6-base);
  color: var(--color-blue);
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.blue-outline-element-button:hover {
  background-color: var(--color-lightBlue);
}

.blue-outline-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.blue-nofill-element-button {
  background-color: transparent;
  text-decoration: none;
  border: 0px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-blue);
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.blue-nofill-element-button:hover {
  color: var(--color-lightBlue);
}

.blue-nofill-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.red-element-button {
  border: 0px;
  background-color: var(--color-red);
  font-size: var(--font-size-h6-base);
  color: #fff;
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.red-element-button:hover {
  background-color: var(--color-lightRed);
}

.red-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.lightRed-element-button {
  border: 0px;
  background-color: var(--color-lightRed);
  font-size: var(--font-size-h6-base);
  color: #fff;
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.lightRed-element-button:hover {
  background-color: var(--color-gray);
}

.lightRed-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.red-outline-element-button {
  background-color: #fff;
  border: 2px solid var(--color-red);
  font-size: var(--font-size-h6-base);
  color: var(--color-red);
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.red-outline-element-button:hover {
  background-color: var(--color-lightRed);
}

.red-outline-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.red-nofill-element-button {
  background-color: transparent;
  text-decoration: none;
  border: 0px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-red);
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.red-nofill-element-button:hover {
  color: var(--color-lightRed);
}

.red-nofill-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.gray-element-button {
  border: 0px;
  background-color: var(--color-gray);
  font-size: var(--font-size-h6-base);
  color: var(--color-primary);
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gray-element-button:hover {
  background-color: var(--color-ultraLightGray);
}

.gray-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.ultraLightGray-element-button {
  border: 0px;
  background-color: var(--color-ultraLightGray);
  font-size: var(--font-size-h6-base);
  color: var(--color-lightPrimary);
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ultraLightGray-element-button:hover {
  background-color: var(--color-gray);
}

.gray-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.coleGray-element-button {
  border: 0px;
  background-color: var(--color-cole-gray);
  font-size: var(--font-size-h6-base);
  color: var(--color-lightPrimary);
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.coleGray-element-button:hover {
  background-color: var(--color-gray);
}

.coleGray-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.yellow-element-button {
  border: 0px;
  background-color: var(--color-yellow);
  font-size: var(--font-size-h6-base);
  color: var(--color-primary);
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.yellow-element-button:hover {
  background-color: var(--color-lightYellow);
}

.yellow-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.green-element-button {
  border: 0px;
  background-color: var(--color-green);
  font-size: var(--font-size-h6-base);
  color: #fff;
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.green-element-button:hover {
  background-color: var(--color-lightGreen);
}

.green-element-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.green-outline-element-button {
  background-color: #fff;
  border: 2px solid var(--color-green);
  font-size: var(--font-size-h6-base);
  color: var(--color-green);
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.green-outline-element-button:hover {
  background-color: var(--color-lightGreen);
}

.green-outline-element-button[aria-disabled="true"] {
  pointer-events: none;
  background-color: var(--color-lightGreen);
}

.modal-confirm-button {
  border: none;
  border-top: 1px solid var(--color-primary);
}

//

.sidebar-circular-button {
  background-color: #40516f !important;
  border-radius: 50% !important;
  padding: 5px !important;
  color: #fff !important;
  &--reverse {
    transform: rotate(180deg);
  }
}
.sidebar-circular-button:hover {
  background-color: #1a283e !important;
}

.side-bar-footer-button {
  background-color: #40516f !important;
  padding: 10px 8px !important;
  color: #fff !important;
  border-radius: 9;
}
.side-bar-footer-button:hover {
  background-color: #1a283e !important;
}

.red-button {
  background-color: #e02020 !important;
  color: #fff !important;
  &:hover {
    opacity: 0.54;
  }
}

.error-outline-button {
  background-color: #fff !important;
  color: #e02020 !important;
  border: 1px solid #e02020 !important;
  &--bottom {
    border-radius: 0 0 0.28571429rem 0.28571429rem !important;
  }
}

.blue-button {
  background-color: #337ef3 !important;
  color: #fff !important;
}
.blue-button:hover {
  opacity: 0.38;
}

.blue-rectangle-button {
  background-color: #337ef3 !important;
  border-radius: 0 !important;
  margin-right: 0 !important;
  color: #fff !important;
}

.blue-rectangle-button:hover {
  background-color: #89b1f2 !important;
}

.blue-disabled-button {
  background-color: rgba(#337ef3, 0.38);
  color: #fff !important;
  cursor: none;
}

.template-button {
  padding-right: 1.5em;
  padding-top: 1em;
  padding-bottom: 1em;
  cursor: pointer;
}
.template-button:hover {
  opacity: 0.38;
}

.caption-button {
  font-size: 10px;
  font-weight: 500;
  cursor: pointer;
  border-bottom: 2px solid #f6d662;
  -webkit-text-decoration: "underline";
  text-decoration: "underline";
}

.caption-button:hover {
  opacity: 0.38;
}

.circular-add {
  position: relative;
  cursor: pointer;
  display: block;
  width: 2em;
  height: 2em;
  border-radius: 100%;
  border: 1px solid #1a283e;
  margin: 0;
  padding: 0;
  color: white;
  background-color: #1a283e;
  overflow: hidden;
  white-space: nowrap;
}
.circular-add::after {
  content: "+";
  font-size: 1.5em;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circular-add:hover {
  opacity: 0.38;
}

.circular-remove {
  position: relative;
  cursor: pointer;
  display: block;
  width: 2em;
  height: 2em;
  border-radius: 100%;
  border: 1px solid #e02020;
  margin: 0;
  padding: 0;
  color: white;
  background-color: #e02020;
  overflow: hidden;
  white-space: nowrap;
}
.circular-remove::after {
  content: "－";
  font-size: 1.5em;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circular-remove:hover {
  opacity: 0.38;
}

#primary-outline-button {
  background-color: #fff;
  color: #1a283e;
  border: 1px solid #1a283e;
}
#primary-outline-button:hover {
  background-color: #c6c9cf;
}
#primary-outline-button:disabled {
  background-color: #c6c9cf;
  border: none;
}

#white-button {
  background-color: #fff;
  color: #1a283e;
}
#white-button:hover {
  background-color: #c6c9cf;
  color: #1a283e;
}

#blue-button {
  background-color: #337ef3;
  color: #fff;
}
#blue-button:hover {
  background-color: #b1cefa;
}

#primary-nofill-button {
  background-color: transparent;
  color: #1a283e;
}
#primary-nofill-button:hover {
  opacity: 0.38;
}

#white-nofill-button {
  background-color: transparent;
  color: #fff;
  padding: 0;
}
#white-nofill-button:hover {
  background-color: transparent;
  color: #c6c9cf;
}

#primary-nofill-red-button {
  background-color: #fff;
  color: #e02020;
}
#primary-nofill-red-button:hover {
  opacity: 0.38;
}

.primary-nofill-red-button {
  background-color: #fff !important;
  color: #e02020 !important;
}
.primary-nofill-red-button:hover {
  opacity: 0.38;
}

.primary-outline-red-button {
  border: 1px solid #e02020 !important;
  background-color: #fff !important;
  color: #e02020 !important;
}

.primary-outline-red-button:hover {
  opacity: 0.38;
}

.icon-blue-button {
  padding: 2rem 1rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #d1d4d8;
  color: #337ef3;
  &-active {
    color: #fff;
    background-color: #337ef3;
  }
}
.icon-blue-button:hover {
  background-color: rgba(#337ef3, 0.38);
}

.icon-red-button {
  padding: 2rem 1rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #d1d4d8;
  color: #e02020;
  &-active {
    color: #fff;
    background-color: #e02020;
  }
}
.icon-red-button:hover {
  background-color: rgba(#e02020, 0.38);
}

.alert-box {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 18px;
  border-radius: 5px;
  background-color: rgba(#e02020, 0.2);
}

.expected-recipient-alert-message {
  border-radius: 5px;
  background-color: rgba(#e02020, 0.2);
  padding: 10px 15px;
}

.icon-green-button {
  padding: 2.2rem 1rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #d1d4d8;
  font-weight: bold;
  color: #009688;
  font-size: 1.5rem;
  &-active {
    color: #fff;
    background-color: #009688;
  }
}
.icon-green-button:hover {
  background-color: rgba(#009688, 0.38);
}

.icon-green-button-disabled {
  padding: 2.2rem 1rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: unset;
  border-bottom: 1px solid #d1d4d8;
  font-weight: bold;
  color: #009688;
  font-size: 1.5rem;
  opacity: 0.38;
}

.dashed-border {
  border: dashed 1px #d1d4d8;
  margin: 10px 0 10px 0 !important;
}

.parent-border {
  border: solid 1px #d1d4d8;
  margin: 10px 0 10px 0 !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.child-border {
  border: solid 0.5px #d1d4d8;
}

.receipt-border {
  border: solid 0.5px #d1d4d8;
  margin: 0.5px !important;
}

.modal-list-border {
  border: solid 0.5px #d1d4d8;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.modal-detail-border {
  border: solid 0.5px #d1d4d8;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.none-border {
  border-bottom: none !important;
  border-top: none !important;
}

.overflow-y {
  overflow-y: auto;
  overflow-x: hidden !important;
}

.overflow-x {
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
}

.overflow-break-word {
  overflow-wrap: break-word;
}

.overflow-hidden {
  overflow: hidden;
}

.overflowY-scroll {
  overflow-y: scroll;
}

.disable-min-height {
  min-height: 0px !important;
}

.display-left-1 {
  flex: 1;
  min-width: 400px;
  max-width: 500px;
}

.recover-dialog {
  width: 60%;
}

.drop-out {
  width: 40% !important;
}

.nav-button {
  background: white !important;
}

.z-index-10 {
  z-index: 10;
}

.ui.multiple.dropdown > .text {
  position: static;
  padding: 0;
  max-width: 100%;
  margin: 0;
  line-height: 1.21428571em;
}
.ui.multiple.dropdown {
  padding: 0.78571429em 0.78571429em 0.78571429em;
}

.button-group {
  margin: 0 0 0 15px !important;
}

.ui[class*="vertically divided"].grid > .row:before {
  position: absolute;
  content: "";
  top: 0em;
  left: 0px;
  width: calc(100% - 2rem);
  height: 0px;
  margin: 0% 1rem;
  box-shadow: 0px -1px 0px 0px rgba(34, 36, 38, 0.15);
}

.add-image-button {
  width: 150px;
  height: 150px;
}

.disable-button-group {
  border-radius: 0.3rem !important;
  border-bottom-right-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important;
}

/* .ui.multiple.dropdown {
  padding: .22619048em 2.1em .22619048em .35714286em
} */

.blue-outline-button {
  border-radius: 5px !important;
  border: solid 1px #337ef3 !important;
  background-color: #fff !important;
  color: #337ef3 !important;
}

.blue-outline-button:hover {
  color: rgba(51, 126, 243, 0.5) !important;
  border: solid 1px rgba(51, 126, 243, 0.5) !important;
}

.blue-outline-button:focus {
  background-color: none !important;
  color: rgba(51, 126, 243, 0.5) !important;
}

.red-outline-button {
  border-radius: 5px !important;
  border: solid 1px var(--color-red) !important;
  background-color: #fff !important;
  color: var(--color-red) !important;
}

.red-outline-button:hover {
  color: var(--color-lightRed) !important;
  border: solid 1px var(--color-lightRed) !important;
}

.red-outline-button:focus {
  background-color: none !important;
  color: var(--color-lightRed) !important;
}

.loyalty-tile-button {
  width: 233px;
  height: 135px !important;
  background-color: #fff;
  display: flex;
  padding: var(--space-base) var(--space-line);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  text-align: center;
  cursor: pointer;
}

.loyalty-tile-button[aria-selected="true"] {
  background-color: var(--color-ultraLightBlue);
}

.loyalty-tile-button:hover {
  background-color: var(--color-gray);
}

.border-top-bottom-3 {
  border-top: 1px solid #1a283e !important;
  border-bottom: 0px solid #1a283e !important;
}

.dashed {
  border-style: dashed;
}

.inner-unit-currency {
  margin-left: -20px;
}

.require::after {
  margin: -0.2em 0 0 0.2em;
  content: "*";
  color: #db2828;
  display: inline-block;
  vertical-align: top;
}

.discount::after {
  text-decoration: line-through;
  -webkit-text-decoration-color: #e02020;
  text-decoration-color: #e02020;
}

// .note::after {
//   margin: -0.2em 0 0 0.2em;
//   content: "*";
//   color: #1a283e;
//   display: inline-block;
//   vertical-align: top;
// }

.input-error {
  border-color: #e02020 !important;
  background: #fff6f6 !important;
  color: #9f3a38 !important;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-area-hack {
  border: 1px solid var(--color-gray);
  border-radius: 5px;
}

.phone-container {
  background-color: #fff;
  border: 1px solid var(--color-gray);
  border-radius: 5px;
}

.phone-container > .sender {
  padding: var(--space-line) var(--space-line);
  background-color: var(--color-ultraLightBlue);
}
.phone-container > .message-container {
  min-height: 35rem;
  padding: var(--space-line) var(--space-line);
  background-color: #fff;
  border-radius: 5px;
}
.message-wrapper {
  margin-top: var(--space-line);
  width: 70%;
}
.message-wrapper > .title {
  margin-bottom: var(--space-base);
  background-color: var(--color-cole-gray);
  padding: var(--space-base);
  min-height: 2rem;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.message-wrapper > .message {
  background-color: var(--color-cole-gray);
  padding: var(--space-base);
  min-height: 12rem;
  border-radius: 9px;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: var(--font-size-h6-base);
}

.message-wrapper > .image {
  width: 100%;
  margin-bottom: var(--space-line);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.friendtalk-wrapper {
  padding: 0 1.5rem;
  margin-top: 1.5rem;
}

.friendtalk-wrapper .friendtalk-container {
  background-color: #bdd2dc;
  padding: 1.5rem 1.2rem;
  min-height: 30rem;
  min-width: 25rem;
}

.friendtalk .sender-logo {
  width: 3rem;
  height: 3rem;
  border-radius: 15px;
  pointer-events: none;
}

.friendtalk-wrapper .right-section {
  padding-top: 5px;
  margin-left: 10px;
}

.friendtalk .right-container {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 10px;
}
.friendtalk .right-text-wrapper {
  padding: 10px;
  white-space: break-spaces;
}

.right-section #wide {
  width: 21.4rem;
}
.right-section #text {
  width: 18rem;
}

.right-section #normal {
  width: 18rem;
}

.right-section .call-btn {
  color: #000;
  text-align: center;
  width: 100%;
  font-size: 14px;
  border: 1px solid #d1d4d8;
  border-radius: 3px;
  padding: 10px;
  margin-top: 5px;
  font-weight: 500;
  background-color: #f6f7f8;
}

.message-report-container {
  background-color: #fff;
  padding: 1.5rem 1.2rem;
}

.call-btn:first-child {
  margin-top: 15px;
}

.call-btn:hover {
  text-decoration: none;
}

.date-row {
  height: 21px;
  background-color: #838b96;
  width: 100%;
  border: white solid 0.5px;
}

.subscription-setting-wrapper {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  padding: 1.5rem 2.14em;
  margin-bottom: 2.14rem;
  display: flex;
}
.subscription-setting-wrapper:last-child {
  margin-bottom: 0px;
}

.subscription-prod-thumbnail {
  border-radius: 5px;
  border: 2px solid #1a283e;
  padding: 1.75rem;
  background-color: #fff;
  max-width: 440px;
  margin-right: auto;
  margin-left: auto;
}

.subscription-detail-image {
  pointer-events: none;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.loyalty-introduce {
  background-color: #f6d661;
  color: #1a283e;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.loyalty-input-display {
  border-radius: 5;
  background-color: #f9f9f9;
  border: 1px solid #d1d4d8;
  height: 50px;
}

/* React-Player */
.player-wrapper {
  position: relative;
}

/* Loader */

.white-loader,
.white-loader:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.white-loader {
  font-size: 10px;
  position: relative;
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid #fff;
  transform: translateZ(0);
  -webkit-animation: load-spinner 1.4s infinite linear;
  animation: load-spinner 1.4s infinite linear;
}

.white-loader-small,
.white-loader-small:after {
  border-radius: 50%;
  width: 15px;
  height: 15px;
}
.white-loader-small {
  font-size: 8px;
  position: relative;
  border-top: 3px solid rgba(255, 255, 255, 0.2);
  border-right: 3px solid rgba(255, 255, 255, 0.2);
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid #fff;
  transform: translateZ(0);
  -webkit-animation: load-spinner 1.4s infinite linear;
  animation: load-spinner 1.4s infinite linear;
}

.primary-loader,
.primary-loader:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.primary-loader {
  font-size: 10px;
  position: relative;
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid var(--color-blue);
  transform: translateZ(0);
  -webkit-animation: load-spinner 1.4s infinite linear;
  animation: load-spinner 1.4s infinite linear;
}

.blue-loader,
.blue-loader:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.blue-loader {
  font-size: 10px;
  position: relative;
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid var(--color-blue);
  transform: translateZ(0);
  -webkit-animation: load-spinner 1.4s infinite linear;
  animation: load-spinner 1.4s infinite linear;
}

.red-loader,
.red-loader:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.red-loader {
  font-size: 10px;
  position: relative;
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid var(--color-red);
  transform: translateZ(0);
  -webkit-animation: load-spinner 1.4s infinite linear;
  animation: load-spinner 1.4s infinite linear;
}
/* White Dimmer (Inverted) */

.ui.inverted.dimmer .ui.loader {
  color: #f6d661;
}

.ui.inverted.dimmer .ui.loader:before {
  border-color: #f6d661;
}

.ui.inverted.dimmer .ui.loader:after {
  border-color: #1a283e transparent transparent;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.7);
}

.scrolling.dimmable > .dimmer {
  justify-content: center;
}

.passcode-input {
  border: 1px solid #d1d4d8;
  border-radius: 5px;
  color: #1a283e !important;
  background-color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 24px !important;
  text-align: center !important;
}

.passcode-sub-input {
  width: 90px !important;
  height: 90px !important;
  text-align: center !important;
}
.pincode-container {
  color: #1a283e;
  position: relative;
  width: calc(60ch);
  padding: 15px;
  background-color: #fff;
  overflow: auto;
  border: solid 0.5px #d1d4d8;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.pincode-input-wrapper {
  gap: 10px;
}
.pincode-input {
  border: 1px solid #d1d4d8;
  border-radius: 5px;
  color: #1a283e !important;
  background-color: #ffffff;
  font-size: 24px !important;
  text-align: center !important;
}

.pincode-sub-input {
  width: 90px;
  height: 120px;
  text-align: center !important;
}
.pincode-error-wrapper {
  width: 400px;
  padding: 1em 1.5em !important;
}
.pincode-button {
  padding: 15px 30px !important;
}

.contractNotibar {
  height: auto;
  position: absolute;
  width: 100%;
  top: 42.5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #838896;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.customer-info-body {
  padding: 15px 24px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0px #d1d4d8;
}

.empty-report-body {
  padding: 10em 1em;
  display: flex;
  height: 100%;
  justify-content: center;
}

// DATA_SHEET

.data-grid-container .data-grid .cell {
  vertical-align: middle;
}

.data-grid-container .data-grid .cell > input {
  outline: none !important;
  border: 2px solid #1a283e;
  text-align: left;
  width: calc(100% - 6px);
  height: inherit;
  background: none;
  display: block;
}

.data-grid-container .data-grid .cell.read-only {
  background: #efeff4;
  color: #999;
  text-align: center;
}

.data-grid-container .data-grid .cell.updated {
  background-color: rgba(0, 145, 253, 0.16);
  transition: background-color 0ms ease;
}
.data-grid-container .data-grid .cell {
  height: 17px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: cell;
  background-color: unset;
  transition: background-color 500ms ease;
  vertical-align: middle;
  text-align: center;
  border: 1px solid #ddd;
  padding: 0;
}
.data-grid-container .data-grid .cell.selected {
  border: 1px double #1a283e;
  transition: none;
  box-shadow: inset 0 -100px 0 rgba(26, 40, 62, 0.15);
}

.data-grid-container .data-grid .cell.updated {
  background-color: rgba(0, 145, 253, 0.16);
  transition: background-color 0ms ease;
}
.data-grid-container .data-grid .cell {
  height: 17px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: cell;
  background-color: unset;
  transition: background-color 500ms ease;
  vertical-align: middle;
  text-align: center;
  border: 1px solid #ddd;
  padding: 0;
}

.option-label {
  border-top: 1px solid #d1d4d8;
  padding: 15px 10px;
  cursor: pointer;
}

.option-label:hover {
  background-color: #c6c9cf;
}

// CUSTOM TABLE

.table-header {
  background-color: #fff;
  // border-bottom: 0.5px solid #d1d4d8;
}

.table-row {
  display: flex;
  line-height: 1.3;
}

.table-cell,
.table-cell-header {
  flex: 1 1 100%;
  display: flex;
  align-items: center;
}

.table-cell-header:first-child,
.table-cell:first-child {
  padding-left: 16px;
}

.table-cell-header {
  color: #1a283e;
  font-weight: 600;
  border-bottom: 1px #1a283e solid;
}

.table-cell,
.table-cell-header {
  height: 48px;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 8px;
}

.table-cell {
  color: #1a283e;
  font-weight: 300;
  background-color: #f9f9f9;
  border-right: 1px solid #d1d4d8;
  border-bottom: 1px solid #d1d4d8;
}

.table-cell:last-child {
  border-right: none;
}

.table-cell .table-cell-switch {
  background-color: #fff;
}

.cell-input {
  background-color: #f9f9f9;
  border: none;
  height: 48px;
  width: 100%;
}
.cell-input:hover,
:focus {
  outline: none !important;
}

.horizontal-table {
  border-top: 1px solid var(--color-gray);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.horizontal-table-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--color-gray);
}

.horizontal-table-cell-title-wrapper {
  padding: 0.7rem 1rem;
  background-color: #efeff4;
  width: 30%;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.horizontal-table-cell-title {
  color: #1a283e;
  font-weight: 600;
}

.horizontal-table-cell-value {
  padding: 0.7rem 1rem;
  background-color: #fff;
  color: #1a283e;
  font-weight: 300;
  border: none;
  height: 48px;
  width: 100%;
}

.horizontal-table-cell-value:hover,
:focus {
  outline: none !important;
}

.supplier-switch-text-wrapper {
  margin-top: var(--space-line);
  padding: 0.7rem 1rem;
  border-top: 1px solid var(--color-gray);
  border-bottom: 1px solid var(--color-gray);
}

.css-ygjjsi-control:hover {
  border-color: #1a283e !important;
}

// Material Table
.MuiPaper-elevation2 {
  box-shadow: none !important;
}
// .MuiOutlinedInput-notchedOutline {
//   border: 2px solid #1a283e !important;
// }

.dashboard-panel-wrapper {
  max-width: 80rem;
  margin: 0 auto;
  width: 100%;
  // border-top: 1px solid #d1d4d8;
  // &:first-child {
  //   border-top: none;
  // }
}

.dashboard-panel-sub-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: -1.4rem;
  margin-left: -2rem;
  // padding: 0 2.5em;
}

.dashboard-panel-layout {
  max-width: calc(100% - 2rem);
  // margin-top: 1.2rem;
  // margin-left: 1rem;
  flex: 1 1 100%;
}

.dashboard-panel {
  display: flex;
  flex-wrap: wrap;
  min-width: 0;
  &-margin {
    min-width: 0;
    max-width: calc(100% - 2rem);
    margin-top: 1.4rem;
    margin-left: 2rem;
  }
  &-left {
    flex: 1 1 22rem;
  }
  &-right {
    flex: 2 2 45.5rem;
  }
  &-prod-margin {
    min-width: 0;
    max-width: calc(100% - 2rem);
    margin-top: 1.4rem;
  }
  &-70 {
    flex: 1 1 47.25rem;
  }
  &-30 {
    flex: 1 1 20.25rem;
  }
}

.dashboard-panel-content-margin {
  margin-top: 1.5rem;
  &:first-child {
    margin-top: 0;
  }
}

.panel-card {
  background-color: #fff;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  outline: 0.1rem solid transparent;
}

.reports-wrapper {
  margin: 0 auto;
}

.reports-layout {
  margin-top: 1.5rem;
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
}

.report-layout-narrow {
  margin-top: 1.5rem;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}

.reports-panel {
  background-color: #fff;
  border-radius: 5px;
  outline: 0.1rem solid transparent;
  padding: 1.2em 1em;
  margin: 1em 0.8em 0 0;
}

.table-th-agreement-provider {
  padding: 0.5em;
  border: 1px solid #999;
  text-align: left;
  background-color: #ccc;
}

.table-td-agreement-provider {
  padding: 0.5em;
  border: 1px solid #999;
}

.footnote::after {
  margin: -0.1em 0 0 0.1em;
  content: "*";
  color: #1a283e;
  display: inline-block;
  vertical-align: top;
}

.filter-comp {
  background-color: #d1d4d8;
  padding: 0.714rem 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.filter-comp:hover {
  background-color: #c6c9cf;
}

.inventory-comp {
  border-top: 1px solid #d1d4d8;
  padding: 14px 20px;
  display: flex;
  cursor: pointer;
  &-no-focus {
    cursor: unset;
  }
  &-active {
    background-color: #c6c9cf;
  }
  &-flex-column {
    flex-direction: column;
  }
}
.inventory-comp:hover {
  background-color: #c6c9cf;
}

.inventory-comp-disabled {
  border-top: 1px solid #d1d4d8;
  padding: 1.2rem 1.5rem;
  display: flex;
  cursor: unset;
  opacity: 0.38;
  &-flex-column {
    flex-direction: column;
  }
}

.tag-comp {
  border-radius: 12px;
  min-height: 40px;
  padding: 0px var(--space-base);
  margin: var(--space-base);
  border: 1px solid var(--color-gray);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag-comp:hover {
  opacity: 0.58;
}

.message-report-box {
  width: 30%;
  border: 1px solid #1a283e;
}

.message-report-box .box-header {
  border-bottom: 1px solid #1a283e;
  background-color: #1a283e;
  color: #fff;
  padding: 1rem 1.25rem;
}

.message-report-box .box-body {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 25rem;
  padding: 1rem 1.25rem;
}
.campaign-editor-container {
  background-color: #fff;
  padding: 1.5rem 2rem;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  &--header {
    // display: flex;
    // flex-direction: row;
  }
}

.account-icon-container {
  padding: 0 var(--space-base);
  border-radius: 5px;
  height: 40px;
  gap: 4px;
  cursor: pointer;
  &:hover {
    background-color: #ececec;
  }
}

.account-dropdown-container {
  opacity: 1;
  transform: none;
  transition: opacity 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 42px;
  right: 38px;
  transform-origin: 280px 0px;
  background-color: rgb(255, 255, 255);
  color: rgb(18, 24, 40);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: rgb(100 116 139 / 12%) 0px 10px 15px;
  background-image: none;
  position: absolute;
  overflow: hidden auto;
  min-width: 16px;
  min-height: 16px;
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);
  outline: 0px;
  border-color: rgb(230, 232, 240);
  border-style: solid;
  border-width: 1px;
  width: 200px;
  margin-top: 8px;
  margin-bottom: 8px;
  z-index: 1500;
}
.account-dropdown-profile-content {
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 5px 0px;
  border-radius: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  min-height: 40px;
  padding: 6px 16px;
  box-sizing: border-box;
  white-space: nowrap;
  &:hover {
    background-color: #efefef;
  }
}

.account-dropdown-profile-divider {
  margin: 0px;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgb(230, 232, 240);
}

.account-dropdown-profile-icon {
  color: rgb(107, 114, 128);
  margin-right: 16px;
  font-size: 1.1rem;
}

.report-alert-box {
  position: fixed;
  width: calc(100% - 240px);
  height: calc(100vh - 200px);
}

.report-nav-container {
  padding: 1.5rem 0;
}

.report-nav-container .report-nav-wrapper {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #1a283e;
}

.report-nav-wrapper .report-nav {
  font-size: var(--font-size-h6-medium);
  color: var(--color-primary);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.2rem;
  margin-right: 1.5rem;
  cursor: pointer;
  word-break: keep-all;
  text-align: left;
}

.report-nav:hover {
  background-color: var(--color-gray);
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
}

.report-nav-wrapper .report-nav.active {
  color: var(--color-primary);
  font-weight: var(--font-weight-bolder);
  border-bottom: 3px solid #1a283e;
  border-radius: 0;
}
.report-nav.active:hover {
  background-color: var(--color-gray);
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
}

.report-two-item-wrapper {
  display: flex;
  justify-content: space-around;
}

.report-two-item-wrapper > .item {
  width: 50%;
}

.report-phone-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.report-phone-header > .ui.selection.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
}

.report-phone-header > .ui.selection.dropdown > .dropdown.icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.report-phone-header > .ui.dropdown.report-nav .menu {
  min-height: 200px;
}

.report-phone-header > .ui.dropdown .item {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-phone-header > .ui.dropdown .selected.item {
  background-color: var(--color-gray);
}

.report-phone-header .rs-picker-toggle {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-phone-header
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn
  .rs-picker-toggle-clean {
  top: 14px;
}

.report-phone-header
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn
  .rs-picker-toggle-caret {
  top: 14px;
}

.report-phone-header .big-btn {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.report-daily-table {
  width: 100%;
  overflow-x: auto;
}

@media only screen and (max-width: 767px) {
  .ui.table:not(.unstackable).report-daily-table {
    display: block;
  }

  .ui.table:not(.unstackable).report-daily-table tr {
    display: table-row !important;
  }
  .ui.table:not(.unstackable).report-daily-table thead {
    display: table-header-group !important;
  }

  .ui.table:not(.unstackable).report-daily-table tr > th {
    padding: 0.92857143em 0.78571429em !important;
    display: table-cell !important;
    min-width: 90px;
  }

  .ui.table:not(.unstackable).report-daily-table tr > td {
    padding: 0.78571429em 0.78571429em !important;
    display: table-cell !important;
    min-width: 90px;
  }
}

.left-gray-border {
  border-left: 1px solid var(--color-gray);
}

.move-box-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-gray);
  padding: 3px;
  margin-bottom: 5px;
}

.move-box-wrapper .one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stock-stat-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.inventory-attribute-comp {
  // display: flex;
  // align-items: center;
}

.inventory-attribute-comp:not(:last-child) {
  border-right: 1px solid #1a283e;
  padding-right: 24px;
}

.inventory-attribute-comp:not(:first-child) {
  margin-left: 24px;
}

.inventory-list-row {
  display: flex;
  align-items: center;
  background-color: #fff;
}

.inventory-list-row-clickable {
  cursor: pointer;
}

.inventory-list-row-clickable:hover {
  background-color: var(--color-cole-gray) !important;
  width: 100%;
}

.modal-footer-button {
  cursor: pointer;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: var(--font-size-h5-medium);
  font-weight: var(--font-weight-bold);
  border-top: 1px solid #1a283e;
}

.modal-footer-button:hover {
  background-color: var(--color-cole-gray);
}

.modal-footer-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.load-more-items-btn {
  height: 55px;
  background-color: #fff;
  padding: 15px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.load-more-items-btn:hover {
  background-color: #89b1f2;
}

.crop-container {
  position: relative;
  height: 50vh;
}

.custom-table-header {
  cursor: pointer;
}

.custom-table-header:hover {
  background-color: var(--color-cole-gray) !important;
}

.terminal-row {
  background-color: #fff;
  padding: var(--space-base);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.terminal-row:hover {
  background-color: var(--color-cole-gray);
}

.icon-text-button {
  cursor: pointer;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: var(--color-base) var(--color-line);
  margin-bottom: var(--space-line);
}

.icon-text-button:hover {
  background-color: var(--color-cole-gray);
}

.promotion-container {
  margin: var(--space-medium) 0 0 var(--space-medium);
}

.promotion-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.promotion-block {
  border-radius: 9px;
  background-color: #fff;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);
  margin-right: var(--space-line);
  width: 400px;
  min-height: 600px;
  position: relative;
}

.promotion-block > .header {
  padding: var(--space-base) var(--space-line);
  background-color: #fff;
  font-size: var(--font-size-h4-medium);
  font-weight: var(--font-weight-bold);
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  height: 55px;
}
.promotion-block > .bottom {
  padding: var(--space-small) var(--space-line);
  background-color: #fff;
  font-size: var(--font-size-h6-medium);
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.promotion-block > .body {
  background-color: #fff;
  display: flex;
  border-bottom: 1px solid var(--color-gray);
  border-top: 1px solid var(--color-gray);
  justify-content: center;
  align-items: center;
  padding: var(--space-small);
  pointer-events: none;
  max-height: 280px;
  height: 355px;
}
.promotion-block > .bottom > .button-block {
  margin-top: var(--space-line);
  width: 90%;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: var(--space-line);
}

.promotion-block:last-child {
  margin-right: 0;
}

.day-circle {
  background-color: var(--color-blue);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: var(--font-size-h7-base);
  font-weight: var(--font-weight-bold);
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuPlan-page-button {
  background-color: #fff;
  padding: 0.4rem 1.5rem;
  margin-right: 0.7rem;
  cursor: pointer;
  border-radius: 5px;
  min-width: 8.5rem;
  text-align: center;
}

.label-check {
  padding: var(--space-line) var(--space-small);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.label-check:hover {
  background-color: var((--color-cole-gray));
  border-radius: 5px;
}

.stockAction-button {
  background-color: var(--color-cole-gray);
  padding: var(--space-small) var(--space-small);
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 18rem;
  flex: 1;
  cursor: pointer;
}

.stockAction-button[aria-disabled="true"] {
  background-color: var(--color-gray);
}

.stockAction-button:hover {
  background-color: var(--color-ultraLightBlue);
}

.stockAction-button > .label {
  border-radius: 20px;
  background-color: var(--color-blue);
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  text-align: center;
  padding: var(--space-base) var(--space-small);
  margin-top: var(--space-line);
  color: #fff;
}

.stockAction-button > .button {
  margin-top: var(--space-line);
  display: flex;
  justify-content: center;
  align-items: center;
}

.stockAction-guide {
  background-color: var(--color-ultraLightBlue);
  border-radius: 15px;
  padding: var(--space-line) var(--space-small);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h6-base);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
}

.stock-action-excel-confirm-container {
  background-color: #fff;
  padding: var(--space-line) var(--space-small);
  border-radius: 9px;
}

.stock-action-excel-confirm-container > .subtitle-wrapper {
  border-bottom: 1px dashed var(--color-gray);
}

.stock-action-excel-confirm-container > .subtitle-wrapper:not(:first-child) {
  margin-top: var(--space-small);
}

.stock-action-excel-confirm-container > .subtitle-wrapper > .subtitle {
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  color: #fff;
  padding: var(--space-base) var(--space-small);
  border-radius: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: var(--space-base);
}

.stock-action-excel-table-row-wrapper {
  overflow-y: auto;
  position: relative;
  min-height: 400px;
}

.stock-action-sku-label {
  display: flex;
  align-items: center;
  padding: var(--space-base);
  background-color: #fff;
  border-radius: 9px;
  flex: 1;
}

.stock-action-sku-label > .thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 9px;
}

.stock-action-sku-label > .thumbnail .thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 9px;
  -o-object-fit: cover;
  object-fit: cover;
}

.stock-action-sku-label > .no-thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-cole-gray);
  font-size: var(--font-size-h5-base);
  font-weight: var(--font-weight-bold);
}

.stock-action-sku-label > .right {
  display: flex;
  flex: 1;
  align-items: center;
}

.stock-action-sku-label > .right > .info {
  padding: var(--space-base) var(--space-line);
  width: 50%;
}

.stock-action-sku-label > .right > .wrapper {
  width: 50%;
}

.stock-action-sku-label > .right > .wrapper > .quantity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-base) var(--space-line);
}

.stock-select-step-comp {
  padding: var(--space-line) var(--space-small);
  background-color: #fff;
  font-size: var(--font-size-h5-medium);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
  border-bottom: 1px solid var(--color-gray);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 85px;
  cursor: pointer;
}

.stock-select-step-comp:hover {
  background-color: var(--color-cole-gray);
}

.stock-select-step-comp[aria-selected="true"] {
  background-color: var(--color-ultraLightBlue);
}

.stock-select-step-comp[aria-disabled="true"] {
  background-color: var(--color-cole-gray);
  color: var(--color-gray);
}

.stock-select-container {
  display: flex;
  height: 100%;
}

.stock-select-container > .left {
  width: 25%;
  border-right: 1px solid var(--color-gray);
}

.stock-select-container > .right {
  width: 75%;
  padding: var(--space-small) var(--space-small);
  overflow-y: auto;
}

.stock-type-comp {
  border-radius: 9px;
  border: 1px solid var(--color-blue);
  background-color: #fff;
  color: var(--color-primary);
  padding: var(--space-base) var(--space-small);
  cursor: pointer;
  font-size: var(--font-size-h5-medium);
  font-weight: var(--font-weight-bold);
  text-align: center;
  width: 155px;
  min-height: 160px;
}

.stock-type-comp:hover {
  background-color: var(--color-cole-gray);
}

.stock-type-comp[aria-selected="true"] {
  background-color: var(--color-blue);
  color: #fff;
  border: none;
}

.stock-item-option {
  padding: var(--space-base) bar(--space-line);
  background-color: #fff;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray);
}

.stock-item-option:hover {
  background-color: var(--color-cole-gray);
}

.stock-item-option > .thumbnail {
  width: 44px;
  height: 44px;
}

.stock-item-option > .thumbnail .thumbnail {
  width: 44px;
  height: 44px;
  -o-object-fit: cover;
  object-fit: cover;
}

.stock-item-option > .no-thumbnail {
  width: 44px;
  height: 44px;
  background-color: var(--color-cole-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.stock-item-option > .info {
  flex: 1;
  display: flex;
  align-items: center;
}

.stock-item-option > .info > .name {
  width: 50%;
  padding: 0 var(--space-base);
}
.stock-item-option > .info > .model {
  width: 25%;
  padding: 0 var(--space-base);
  &--no-quantity {
    width: 50%;
    padding: 0 var(--space-base);
  }
}
.stock-item-option > .info > .quantity {
  width: 25%;
  padding: 0 var(--space-base);
}

.stock-report-stat-comp {
  width: 25%;
  padding: var(--space-base) var(--space-base);
}
.stock-report-stat-comp > .title {
  font-size: var(--font-size-h5-base);
  font-weight: var(--font-weight-bold);
}
.stock-report-stat-comp > .value {
  margin-top: var(--space-base);
  font-size: var(--font-size-h3-medium);
  font-weight: var(--font-weight-bold);
}

.stock-setting-comp {
  padding: var(--space-line);
  border-bottom: 1px solid var(--color-gray);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.stock-setting-comp > .left {
  width: 75%;
  padding-right: var(--space-base);
}

.stock-setting-comp > .right {
  width: 25%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.customer-attribute-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-base) var(--space-base);
  border-bottom: 1px solid var(--color-gray);
}

.customer-modal-input {
  // background-color: var(--color-cole-gray);
  background-color: #fff;
  border-radius: 5px;
  padding: var(--space-base);
  border: none;
}

.around-coupon-container {
  padding: var(--space-line) var(--space-line);
  background-color: #fff;
  border-radius: 9px;
}

.around-coupon-image-wrapper {
  width: 50%;
  aspect-ratio: 1/1;
}

.around-coupon-image-wrapper .image-style {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.around-info-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-gray);
  padding: var(--space-adjusted-line) 0;
}

.around-info-label > .left {
  font-size: var(--font-size-h6-medium);
}

.around-info-label > .right {
  font-size: var(--font-size-h6-medium);
  font-weight: var(--font-weight-bold);
}

.around-notice-wrapper {
  background-color: var(--color-cole-gray);
  border-radius: 9px;
  padding: var(--space-base) var(--space-base);
  font-size: var(--font-size-h6-base);
  min-height: 200px;
}

.app-cafe24-sync-info {
  background-color: var(--color-cole-gray);
  padding: var(--space-line) var(--space-line);
  font-size: var(--font-size-h7-base);
  border-radius: 9px;
  margin-top: var(--space-base);
  margin-bottom: var(--space-line);
}

.cafe24-coupon-item {
  padding: var(--space-line) 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray);
}

.cafe24-coupon-item .name {
  width: 20%;
  padding: var(--space-adjusted-line);
}
.cafe24-coupon-item .description {
  width: 20%;
  padding: var(--space-adjusted-line);
}
.cafe24-coupon-item .type {
  width: 15%;
  padding: var(--space-adjusted-line);
}
.cafe24-coupon-item .discountMethod {
  width: 12%;
  padding: var(--space-adjusted-line);
}
.cafe24-coupon-item .discountValue {
  width: 13%;
  padding: var(--space-adjusted-line);
}
.cafe24-coupon-item .offline {
  width: 20%;
  padding: var(--space-adjusted-line);
}

.hover-show:hover {
  overflow: visible !important;
  white-space: normal !important;
  height: auto !important;
  max-height: auto !important;
  text-overflow: unset;
  display: flex;
  -webkit-line-clamp: unset;
}

.update-banner-container {
  z-index: 1000;
  position: absolute;
  background-color: #fff;
  border: 2px solid var(--color-primary);
  border-radius: 12px;
  min-height: 420px !important;
  height: auto !important;
  width: 450px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}
.update-banner-container > .body {
  padding: var(--space-line);
  flex: 1;
  display: flex;
  flex-direction: column;
}
.update-banner-container > .body > .image {
  flex: 1;
  cursor: pointer;
}

.update-banner-image {
  max-width: 410px;
}

.waiting-nav {
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;
  position: fixed;
}

.waiting-nav > .waiting-top-container {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  position: relative;
}

.waiting-nav > .waiting-top-container > .waiting-top {
  min-height: 4.28em;
  background: #fff;
  max-width: 480px;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.waiting-top > .waiting-item-wrapper {
  padding: 1em 0;
}

.waiting-body {
  max-width: 420px;
  margin: var(--space-line) auto 0 auto;
  padding: 0 var(--space-small) var(--space-large) var(--space-small);
  overflow-y: auto;
}

.waiting-status-container {
  background-color: #fff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: var(--space-small);
  margin-top: var(--space-line);
  min-height: 300px;
}

.waiting-status-container > .waiting-status {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.waiting-status > .waiting-number {
  text-align: center;
  margin: var(--space-base) 0 var(--space-base) 0;
  font-size: var(--font-size-h1-large);
  font-weight: var(--font-weight-bolder);
}

.waiting-label {
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-lightPrimary);
  text-align: center;
  margin: var(--space-adjusted-line) 0 var(--space-tiny) 0;
}

.waiting-value {
  font-size: var(--font-size-h5-medium);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
  text-align: center;
}

.waiting-store-comp {
  border-radius: 9px;
  border: 1px solid var(--color-gray);
  padding: var(--space-line);
  margin-top: var(--space-medium);
  background-color: #fff;
}

.waiting-store-label {
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-lightPrimary);
  padding: 0;
  &--phone {
    cursor: pointer;
    color: var(--color-blue);
  }
}

.waiting-store-label:not(:first-child) {
  margin-top: var(--space-tiny);
}

.credit-charge-guide-block {
  margin-top: var(--space-base);
  border-radius: 9px;
  background-color: var(--color-cole-gray);
  padding: var(--space-line);
  display: flex;
  flex-wrap: wrap;
}

.credit-charge-guide-block > .comp {
  width: 50%;
}

.credit-charge-list-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--space-base) var(--space-line);
  cursor: pointer;
}

.credit-charge-list-item > .left {
  width: 35%;
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
}

.credit-charge-list-item > .middle {
  width: 35%;
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
  text-align: right;
}

.credit-charge-list-item > .right {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}

.credit-charge-list-item:hover {
  background-color: var(--color-ultraLightBlue);
}

.progress-wrapper {
  background-color: var(--color-lightYellow);
  padding: var(--space-adjusted-line) var(--space-line);
  border-radius: 9px;
  margin-bottom: var(--space-line);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 450px;
}

.progress-wrapper .title {
  font-size: var(--font-size-h4-medium);
  font-weight: var(--font-weight-bold);
  margin-left: var(--space-base);
}

.layout-noti-comp {
  border-bottom: 1px solid var(--color-gray);
  padding: var(--space-base) var(--space-line);
}

.layout-noti-comp > .date {
  font-size: var(--font-size-h6-base);
}
.layout-noti-comp > .title {
  font-size: var(--font-size-h4-base);
  font-weight: var(--font-weight-bold);
  margin-top: var(--space-adjusted-line);
}
.layout-noti-comp > .message {
  font-size: var(--font-size-h6-medium);
  font-weight: var(--font-weight-normal);
  margin-top: var(--space-tiny);
}

.layout-noti-comp:first-child {
  border-top: 1px solid var(--color-gray);
}

.noti-center-badge {
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--color-blue);
  color: #fff;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h7-base);
  margin-left: var(--space-tiny);
  border-radius: 5px;
}
.channel-other-logo {
  width: 7.42rem;
  height: 3.92rem;
  pointer-events: none;
  border-radius: 9px;
  &--big {
    width: 9.275rem;
    height: 4.9rem;
  }
}

.title-badge {
  background-color: var(--color-ultraLightBlue);
  border-radius: 12px;
  padding: var(--space-tiny) var(--space-line);
  color: var(--color-blue) !important;
}

.channel-coupon-option-label {
  padding: var(--space-base) var(--space-adjusted-line);
  cursor: pointer;
}
.channel-coupon-option-label:hover {
  background-color: var(--color-cole-gray);
}

#channel-item-setting .wrapper {
  background-color: #fff;
  border-radius: 9px;
  padding: var(--space-line);
  margin-top: var(--space-line);
}

#channel-stock-setting .wrapper {
  background-color: #fff;
  border-radius: 9px;
  padding: var(--space-line);
  margin-top: var(--space-line);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 60rem;
}

#channel-item-setting .option-type[aria-selected="true"] {
  background-color: var(--color-ultraLightBlue);
  border-radius: 9px;
  cursor: pointer;
  padding: var(--space-line);
  min-width: 250px;
  max-width: 250px;
  min-height: 175px;
}
#channel-item-setting .option-type[aria-selected="false"] {
  background-color: var(--color-cole-gray);
  border-radius: 9px;
  cursor: pointer;
  padding: var(--space-line);
  min-width: 250px;
  max-width: 250px;
  min-height: 175px;
}
.table-status-button {
  min-height: 40px;
  border-radius: 9px;
  padding: 5px 8px;
}

.manual-link-modal {
  // width: 90% !important;
  // height: 100%;
}

.manual-link-modal > .footer-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 55px;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.2);
  padding: var(--space-base) var(--space-line);
}

.manual-link-modal .manual-map-list-wrapper {
  padding: var(--space-line) var(--space-small);
  // flex: 1;
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.manual-map-list-wrapper > .manual-map-item-wrapper {
  width: 50%;
  padding: var(--space-tiny) var(--space-base);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.manual-map-list-wrapper > .manual-map-item-wrapper > .manual-map-item {
  flex: 1;
  border-radius: 12px;
  padding: var(--space-base) var(--space-adjusted-line);
  // background-color: var(--color-ultraLightBlue);
  background-color: var(--color-cole-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-h6-base);
  min-height: 5.7rem;
}

.manual-map-item > .left,
.manual-map-item > .right {
  width: 40%;
}

.manual-link-modal .item-list-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.item-list-container > .item-list-wrapper {
  flex: 1;
  display: flex;
  position: relative;
}

.external-list-container {
  width: 50%;
  border-right: 1px solid var(--color-gray);
}

.unlink-list-container {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.external-list-container > .list-wrapper,
.unlink-list-container > .list-wrapper {
  overflow-y: auto;
  min-height: 500px;
  max-height: 500px;
  border-bottom: 1px solid var(--color-gray);
}

.external-list-container > .title-wrapper,
.unlink-list-container > .title-wrapper {
  padding: var(--space-base) var(--space-adjusted-line);
  font-size: var(--font-size-h4-base);
  font-weight: var(--font-weight-bold);
  min-height: 5rem;
}

.external-list-container > .list-wrapper > .prod-list-item,
.unlink-list-container > .list-wrapper > .prod-list-item {
  padding: var(--space-base);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray);
  cursor: pointer;
}

.external-list-container
  > .list-wrapper
  > .prod-list-item[aria-disabled="true"],
.unlink-list-container > .list-wrapper > .prod-list-item[aria-disabled="true"] {
  cursor: auto;
  background-color: var(--color-cole-gray);
}

.external-list-container > .list-wrapper > .prod-list-item:hover,
.unlink-list-container > .list-wrapper > .prod-list-item:hover {
  background-color: var(--color-cole-gray);
}

.external-list-container > .list-wrapper > .prod-list-item:first-child,
.unlink-list-container > .list-wrapper > .prod-list-item:first-child {
  border-top: none;
}

.prod-list-item > .title {
  width: 35%;
  padding-right: 5px;
  font-size: var(--font-size-h6-medium);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.prod-list-item > .sku {
  width: 30%;
  padding-right: 5px;
  font-size: var(--font-size-h6-base);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}
.prod-list-item > .price {
  width: 15%;
  padding-right: 5px;
  text-align: right;
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}

.prod-list-item > .button {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--color-gray);
}

.prod-list-item > .button[aria-selected="true"] {
  color: var(--color-blue);
}

.circle-number-h4 {
  border: 1px solid var(--color-primary);
  width: 28px;
  height: 28px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: var(--font-size-h5-medium);
  font-weight: var(--font-weight-bold);
}

.resync-exclude-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--space-line);
  border-top: 1px solid var(--color-gray);
  padding-top: var(--space-line);
}

#channel-stock-setting-modal {
  padding: var(--space-small) var(--space-medium) !important;
}

#channel-stock-setting-modal .stock-type-select-wrapper[aria-selected="true"] {
  background-color: var(--color-ultraLightBlue);
  border-radius: 9px;
  cursor: pointer;
  padding: var(--space-line);
  color: var(--color-blue);
}

#channel-stock-setting-modal .stock-type-select-wrapper[aria-selected="false"] {
  background-color: var(--color-cole-gray);
  border-radius: 9px;
  cursor: pointer;
  padding: var(--space-line);
  color: var(--color-lightPrimary);
}

.stock-type-select-wrapper > .sub-detail {
  margin-top: var(--space-line);
  padding: var(--space-line);
  background-color: #fff;
  // background-color: var(--color-cole-gray);
  border-radius: 12px;
}

#channel-stock-setting-modal .share-location-notice {
  background-color: var(--color-cole-gray);
  border-radius: 9px;
  padding: var(--space-line);
  margin-top: var(--space-line);
  font-size: var(--font-size-h6-base);
}

.tier-setting-wrapper {
  margin-top: var(--space-line);
  border-radius: 5px;
  border: 1px solid var(--color-gray);
}

.tier-title-comp {
  border-bottom: 1px solid var(--color-gray);
  padding: var(--space-base);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-modal-wrapper {
  padding: var(--space-base);
  background-color: var(--color-cole-gray);
  border-radius: 5px;
  margin-top: var(--space-base);
}

.order-state-badge {
  background-color: var(--color-ultraLightBlue);
  border-radius: 20px;
  padding: var(--space-tiny) var(--space-line);
  color: var(--color-blue) !important;
}

.customer-grade-noti {
  background-color: var(--color-cole-gray);
  border-radius: 5px;
  padding: var(--space-base);
  margin-top: var(--space-line);
  font-size: var(--font-size-h6-base);
}
.customer-grade-schedule {
  background-color: var(--color-ultraLightBlue);
  border-radius: 5px;
  padding: var(--space-line) var(--space-base);
  margin-top: var(--space-line);
  font-size: var(--font-size-h6-base);
}

.notion-wrapper {
  width: 100%;
  overflow: auto;
  height: 100%;
  border: none;
}

.notion-container {
  width: 100%;
  // max-width: 700px;
  margin: auto;
  padding: 1.5rem;
  height: 100%;
  background-color: #fff !important;
}

.prod-list-thumbnail {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 9px;
}

.prod-list-no-thumbnail {
  width: 44px;
  height: 44px;
  background-color: var(--color-cole-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 9px;
}

.order-stat-item {
  border-radius: 9px;
  background-color: #fff;
  padding: var(--space-line);
  &-clickable {
    cursor: pointer;
  }
}

.order-stat-item {
  &-clickable:hover {
    background-color: var(--color-superUltraLightBlue);
  }
  &-clickable[aria-selected="true"] {
    background-color: var(--color-superUltraLightBlue);
  }
}

.connected-item-badge {
  background-color: var(--color-ultraLightBlue);
  border-radius: 20px;
  padding: 3px var(--space-base);
  color: var(--color-blue) !important;
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-bold);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.search-option-container {
  border: 1px solid var(--color-gray);
  border-radius: 12px;
  padding: var(--space-adjusted-line) var(--space-small);
  background-color: #fff;
}

.search-option-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--space-adjusted-line);
  flex-wrap: wrap;
}

.search-option-wrapper > .date-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.search-option-wrapper > .sub-wrapper {
  display: flex;
  align-items: center;
}

.search-option-range-picker {
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 0px var(--space-base);
  cursor: pointer;
  border: none;
}

.date-wrapper > .short-cut-range {
  padding: var(--space-adjusted-line) var(--space-tiny);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  background-color: #fff;
  min-width: 55px;
  height: 50px;
  cursor: pointer;
}

.short-cut-range {
  border-left: 1px solid var(--color-primary);
}

.short-cut-range:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.search-option-range-picker:hover,
.short-cut-range:hover {
  background-color: var(--color-cole-gray);
}

.report-stat-container {
  width: 25%;
  padding-right: var(--space-line);
  margin-bottom: var(--space-line);
}

.report-stat-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--color-gray);
  background-color: #fff;
}

.report-stat-wrapper > .header {
  flex: 1;
  padding: var(--space-adjusted-line) var(--space-line);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 75px;
  border-bottom: 1px solid var(--color-gray);
  font-size: var(--font-size-h5-base);
  font-weight: var(--font-weight-bold);
}

.report-stat-wrapper > .body {
  max-height: 460px;
  overflow-y: auto;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: var(--space-line);
  &--number {
    font-size: var(--font-size-h2-base);
    font-weight: var(--font-weight-bold);
    align-items: center;
  }
}

.report-graph-container {
  padding-right: var(--space-line);
  margin-bottom: var(--space-line);
  &-w-100 {
    width: 100%;
  }
  &-w-50 {
    width: 50%;
  }
}

.element-button-group-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.element-button-group-wrapper > .group-button {
  height: 44px;
  padding: 0px var(--space-line);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-cole-gray);
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-lightPrimary);
  min-width: 80px;
  cursor: pointer;
}

.element-button-group-wrapper > .group-button:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.element-button-group-wrapper > .group-button:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.element-button-group-wrapper > .group-button:hover {
  background-color: var(--color-gray);
}

.element-button-group-wrapper > .group-button[aria-selected="true"] {
  background-color: var(--color-blue);
  color: #fff;
}

.summary-report-label {
  padding: var(--space-base);
  border-bottom: 1px solid var(--color-gray);
  display: flex;
  align-items: center;
}

.summary-report-label:not(:first-child) {
  margin-top: var(--space-base);
}

.location-sales-listItem {
  width: 50%;
  padding: var(--space-base);
  padding-right: var(--space-line);
  border-bottom: 1px solid var(--color-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.location-sales-listItem > .label {
  font-size: var(--font-size-h6-base);
}
.location-sales-listItem > .value {
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-blue);
}

.narrow-no-display {
  display: block;
}

#stock-upload > .stock-type-wrapper {
  width: 50%;
  padding: 0 var(--space-line) 0 0;
}

.stock-type {
  background-color: var(--color-cole-gray);
  padding: var(--space-small) var(--space-line);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stock-type:not(:first-child) {
  margin-top: var(--space-line);
}

.no-pointer-event {
  pointer-events: none;
}

.pin-box {
  border-radius: 12px;
  border: 1px solid var(--color-gray);
  background-color: #fff;
  padding: var(--space-line);
}
.pin-box:not(:first-child) {
  margin-left: var(--space-line);
}

.pin-box > input {
  border: none;
  font-size: var(--font-size-h4-base);
  font-weight: var(--font-weight-bold);
  width: 80px;
  text-align: center;
}

#order-search-filter > .order-search-wrapper {
  border-radius: 9px;
  border: 1px solid var(--color-gray);
  background-color: #fff;
  padding: var(--space-adjusted-line) var(--space-line);
}

#order-search-filter > .remove-badge {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: var(--color-lightRed);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

#order-search-filter .search-filter-input {
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  height: 40px;
  padding: 0 var(--space-base);
  min-width: 150px;
  margin-right: var(--space-tiny);
  border-radius: 9px;
  background-color: #fff;
  border: 1px solid var(--color-gray);
}

#order-search-filter .tag-comp {
  border-radius: 12px;
  padding: var(--space-tiny) var(--space-adjusted-line);
  margin-bottom: var(--space-base);
}

.attribute-modal-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.attribute-modal-body > .left {
  width: 40%;
  padding: var(--space-small);
}

.attribute-modal-body > .right {
  width: 60%;
  padding-left: var(--space-line);
}

.attribute-modal-body > .right .attribute {
  padding: var(--space-base) var(--space-line);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-gray);
}

.attribute-modal-body > .right .attribute:not(:first-child) {
  margin-top: var(--space-adjusted-line);
}

.onoff-logo-wrapper-mini {
  width: 183px;
  height: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-gray);
  border-radius: 9px;
}

.onoff-release-wrapper {
  background-color: var(--color-superUltraLightBlue);
  border-radius: 12px;
  max-width: 80rem;
  padding: var(--space-line);
  margin: var(--space-line) auto;
}

.onoff-banner-preview-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 4/1;
  border-radius: 15px;
  overflow: hidden;
}

.coupon-location-container {
  background-color: var(--color-cole-gray);
  padding: var(--space-base);
  border-radius: 9px;
}

.promotion-video-frame {
  aspect-ratio: 4/3;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-gray);
  border-radius: 9px;
}

.mirror-image-wrapper {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  border: 1px solid var(--color-gray);
  border-radius: 5px;
}

.custom-overlay {
  position: fixed;
  // display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  user-select: none;
}

.custom-overlay > .body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  flex-direction: column;
  width: 80%;
  height: auto;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  user-select: text;
}

.custom-overlay > .body > .container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.customer-modal-header {
  padding: var(--space-line);
}

.customer-modal-header > .info-wrapper {
  display: flex;
  align-items: flex-start;
  margin-top: var(--space-base);
}

.customer-modal-header > .info-wrapper > .info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
}

.customer-modal-header > .info-wrapper > .note {
  padding-left: var(--space-line);
  width: 25%;
  display: flex;
  flex-direction: column;
}

.customer-modal-header > .info-wrapper > .note > .note-style {
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  min-height: 10rem;
  padding: var(--space-tiny);
}

.customer-modal-body {
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: var(--space-line);
  display: flex !important;
  flex-direction: column;
  flex: 1;
  min-height: 30rem;
  // max-height: 35rem;
  max-height: 60vh;
}

.customer-modal-body > .body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.customer-modal-body > .body > .body-container {
  flex: 1;
  display: flex;
  align-items: flex-start;
}

.customer-modal-body > .body > .body-container > .scroll {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: calc(60vh - 20px - 40px - 20px);
  &::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }
}

.customer-modal-points-wrapper {
  max-height: 100vh;
  overflow-y: auto;
}

.customer-coupon-container {
  padding: var(--space-base);
}

.customer-coupon-container > .customer-coupon-wrapper {
  display: flex;
  align-items: center;
  padding-right: var(--space-line);
  border: 1px solid var(--color-gray);
  border-radius: 9px;
}

.customer-coupon-wrapper > .coupon-image-wrapper {
  width: 130px;
  height: 130px;
  overflow: hidden;
  border-radius: 9px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.customer-coupon-wrapper > .coupon-image-wrapper > .coupon-image {
  width: 100%;
  height: 100%;
}

.customer-coupon-wrapper > .info {
  flex: 1;
  margin-left: var(--space-adjusted-line);
}

.order-modal-body {
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  flex: 1;
  // min-height: 35rem;
  min-height: 80%;
  max-height: 50rem;
  overflow-y: auto;
}

.order-modal-body > .info {
  flex: 1;
  padding: var(--space-line);
}

.order-modal-body > .payment {
  width: 40%;
  padding: var(--space-line);
  min-width: 27rem;
  position: sticky;
  top: 0;
}

.order-modal-body > .payment > .box-wrapper {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: var(--space-line);
}

.order-record-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 25rem;
}

.order-record-wrapper > .dashed-line {
  margin: 0 var(--space-line);
  border-bottom: 2px dashed var(--color-gray);
  flex: 1;
}

#order-modal .order-item-wrapper {
  max-width: 40vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-modal-action-button-wrapper {
  position: sticky;
  background-color: #fff;
  top: 0;
  padding: var(--space-base) 0;
}

.title-icon {
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-cole-gray);
  border-radius: 15px;
  margin-right: var(--space-base);
}

#tag-mgmt .description-wrapper,
#cherry-picker .description-wrapper {
  border-radius: 9px;
  background-color: var(--color-cole-gray);
  font-size: var(--font-size-h6-base);
  color: var(--color-primary);
  padding: var(--space-base);
  display: flex;
  align-items: center;
  width: fit-content;
}

#tag-mgmt .tag-width {
  width: 25%;
  position: relative;
  padding-right: var(--space-base);
  padding-bottom: var(--space-base);
}

.tag-width > .tag-wrapper {
  border: 1px solid var(--color-gray);
  border-radius: 12px;
  padding: var(--space-line);
  cursor: pointer;
  background-color: #fff;
  min-height: 210px;
  max-height: 210px;
  display: flex;
  flex-direction: column;
}

.tag-wrapper:hover {
  box-shadow: 0px 0px 10px rgba(#000, 0.1);
}

.tag-wrapper[aria-selected="true"] {
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(#000, 0.2);
  position: absolute;
  min-height: 300px;
  max-height: 800px;
  top: 0;
  left: 0;
  right: var(--space-base);
}

.tag-wrapper > .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 40px;
}

.tag-wrapper > .header > .auto-icon {
  background-color: var(--color-superUltraLightBlue);
  border-radius: 9px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag-wrapper > .body {
  border-top: 1px solid var(--color-gray);
  padding-top: var(--space-base);
  margin: var(--space-base) 0;
  overflow-y: hidden;
}

.tag-wrapper > .action-wrapper {
  border-top: 1px solid var(--color-gray);
  margin-top: auto;
  padding-top: var(--space-base);
}

.tag-wrapper > .action-wrapper > .actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--space-base);
}

.tag-modal-body {
  background-color: #fff;
  min-height: 40rem;
  max-height: 55rem;
  overflow-y: auto;
  padding: var(--space-line);
  display: flex;
  align-items: flex-start;
  flex: 1;
}

.tag-modal-body > .editor {
  flex: 1;
  padding-right: var(--space-line);
}

.tag-modal-body > .summary {
  width: 35%;
  min-width: 25rem;
  position: sticky;
  top: 0;
}

.tag-modal-body > .summary .box-wrapper {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: var(--space-line);
}

.editor > .condition-wrapper {
  border: 1px solid var(--color-gray);
  border-radius: 9px;
  padding: var(--space-adjusted-line);
  background-color: #fff;
  margin-top: var(--space-line);
}

.editor > .condition-wrapper > .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-size-h5-base);
  font-weight: var(--font-weight-bold);
}

.editor > .condition-wrapper > .filter-line {
  padding: var(--space-tiny) 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: var(--font-size-h5-base);
}

.filter-switch-wrapper {
  background-color: var(--color-cole-gray);
  border-radius: 15px;
  padding: var(--space-tiny) var(--space-base);
  margin: 0 var(--space-base);
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-switch-wrapper > .filter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-tiny) var(--space-line);
  border-radius: 15px;
  background-color: var(--color-cole-gray);
  font-size: var(--font-size-h5-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-lightPrimary);
  cursor: pointer;
  margin-right: var(--space-tiny);
}

.filter-switch-wrapper > .filter:hover {
  background-color: var(--color-gray);
}

.filter-switch-wrapper > .filter[aria-selected="true"] {
  background-color: var(--color-superUltraLightBlue);
  color: var(--color-blue);
}

.filter-switch-wrapper > .filter:last-child {
  margin-right: 0;
}

.filter-input-wrapper {
  background-color: #fff;
  border-radius: 15px;
  padding: var(--space-tiny) var(--space-base);
  margin: var(--space-tiny) var(--space-base);
  border: 1px solid var(--color-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-h5-base);
  font-weight: var(--font-weight-bold);
}

.filter-input-wrapper > input {
  border: none;
  background-color: #fff;
  margin-left: var(--space-base);
  min-width: 120px;
  min-height: 40px;
  width: fit-content;
  font-family: "Pretendard-Regular", sans-serif !important;
}

.notice-box {
  background-color: #c5e7e4;
  color: var(--color-primary);
  font-size: var(--font-size-h6-base);
  border-radius: 9px;
  padding: var(--space-base);
}

.error-warning-box {
  background-color: var(--color-cole-gray);
  color: var(--color-red);
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  border-radius: 9px;
  padding: var(--space-base);
  margin-bottom: var(--space-line);
}

.campaign-modal-body {
  display: flex;
  align-items: center;
  background-color: var(--color-cole-gray);
  flex: 1;
  width: 100%;
  max-height: calc(100vh - 4.3rem);
}

.campaign-modal-body > .menu-container {
  background-color: #fff;
  overflow-y: auto;
  width: 30%;
  min-width: 450px;
  height: 100%;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.campaign-modal-body > .menu-container::-webkit-scrollbar {
  width: 0px !important;
}

.campaign-modal-body > .preview-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.campaign-modal-body > .preview-container > .inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-container > .list-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.inner-container > .list-wrapper > .type-select {
  padding: var(--space-adjusted-line);
  align-self: stretch;
}

.inner-container > .list-wrapper > .type-select > .template-select-wrapper {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  padding: var(--space-line);
  cursor: pointer;
  min-width: 20rem;
}

.inner-container > .list-wrapper > .type-select > .type-select-wrapper {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  min-height: 22.43rem;
  width: 18rem;
  padding: var(--space-line);
  cursor: pointer;
}

.inner-container
  > .list-wrapper
  > .type-select
  > .type-select-wrapper[aria-selected="true"],
.inner-container
  > .list-wrapper
  > .type-select
  > .template-select-wrapper[aria-selected="true"] {
  background-color: var(--color-superUltraLightBlue);
}

.inner-container
  > .list-wrapper
  > .type-select
  > .type-select-wrapper[aria-disabled="true"],
.inner-container
  > .list-wrapper
  > .type-select
  > .template-select-wrapper[aria-disabled="true"] {
  opacity: 0.5;
  cursor: text;
}

// .inner-container > .list-wrapper > .type-select-wrapper:not(:first-child) {
//   margin-left: var(--space-small);
// }

.inner-container > .list-wrapper > .type-select > .type-select-wrapper:hover,
.inner-container
  > .list-wrapper
  > .type-select
  > .template-select-wrapper:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.list-wrapper > .type-select > .type-select-wrapper > .header,
.list-wrapper > .type-select > .template-select-wrapper > .header {
  font-size: var(--font-size-h4-base);
  font-weight: var(--font-weight-bold);
}

.list-wrapper > .type-select > .type-select-wrapper > .body,
.list-wrapper > .type-select > .template-select-wrapper > .body {
  font-size: var(--font-size-h6-base);
  margin-top: var(--space-base);
}

.list-wrapper > .type-select > .template-select-wrapper > .body > .body-image {
  width: 50%;
  border-radius: 12px;
  overflow: hidden;
  pointer-events: none;
  &-friendtalk-normal {
    width: 12.28rem;
  }
  &-friendtalk-wide {
    width: 20rem;
  }
  &-alimtalk {
    width: 20rem;
  }
}

.list-wrapper > .type-select > .type-select-wrapper > .header > .icon {
  width: 75px;
  height: 75px;
  border: 1px solid var(--color-gray);
  padding: var(--space-base);
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  margin-right: var(--space-base);
}

.type-select-wrapper > .header > .icon > img {
  width: 100%;
  height: 100%;
}

.campaign-modal-body > .menu-container > .menu {
  padding: var(--space-line);
  border-bottom: 1px solid var(--color-gray);
  cursor: pointer;
}

.campaign-modal-body > .menu-container > .menu[aria-selected="true"] {
  // background-color: var(--color-superUltraLightBlue);
  border: 2px solid var(--color-green);
}

.menu-container > .menu > .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-size-h5-base);
  font-weight: var(--font-weight-bold);
}

.menu-container > .menu > .menu-body {
  margin-top: var(--space-adjusted-line);
}

.menu-container > .menu > .menu-body > .menu-selected-wrapper {
  background-color: var(--color-ultraLightYellow);
  border-radius: 9px;
  padding: var(--space-base) var(--space-adjusted-line);
  font-size: var(--font-size-h5-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-blue);
  width: fit-content;
  min-width: 15rem;
}

.preview-container > .inner-container > .smartphone {
  min-height: 45rem;
  max-height: calc(80vh - 4.3rem);
  width: 25rem;
  border: 1px solid var(--color-primary);
  border-radius: 9px;
  // box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  &-report {
    height: calc(80vh - 4.3rem);
  }
}

.preview-container > .inner-container > .smartphone::-webkit-scrollbar {
  width: 0px !important;
}

.inner-container > .smartphone > .text-message {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
}

.inner-container > .smartphone > .text-message > .header {
  background-color: var(--color-cole-gray);
  padding: var(--space-line);
  border-bottom: 1px solid var(--color-gray);
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.inner-container > .smartphone > .text-message > .body {
  padding: var(--space-line);
  flex: 1;
}

.smartphone > .text-message > .body > .image {
  width: 100%;
  margin-bottom: var(--space-line);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.inner-container > .smartphone > .text-message > .body .title {
  background-color: var(--color-cole-gray);
  padding: var(--space-base);
  margin-top: var(--space-base);
  border-radius: 9px;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.inner-container > .smartphone > .text-message > .body .message {
  background-color: var(--color-cole-gray);
  padding: var(--space-base);
  margin-top: var(--space-base);
  border-radius: 9px;
  min-height: 14.28rem;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.inner-container > .smartphone > .kakao-message {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #bdd2dc;
  padding: var(--space-line);
}

.smartphone > .kakao-message > .wrapper {
  display: flex;
  align-items: flex-start;
  flex: 1;
}

.kakao-message > .wrapper > .sender-logo {
  width: 3rem;
  height: 3rem;
  border-radius: 15px;
  pointer-events: none;
  background-image: url("./img/graphic/empty_product.png");
}

.kakao-message > .wrapper > .right-section {
  margin-top: var(--space-tiny);
  margin-left: var(--space-base);
  display: flex;
  flex-direction: column;
  flex: 1;
}

.kakao-message > .wrapper > .right-section > .body {
  background-color: #fff;
  border-radius: 9px;
  min-height: 14.28rem;
  margin-top: var(--space-base);
  &-normal {
    width: 90%;
  }
  &-wide {
    width: 100%;
  }
}

.kakao-message > .wrapper > .right-section > .body-wide > .image {
  width: 100%;
  pointer-events: none;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  overflow: hidden;
}
.kakao-message > .wrapper > .right-section > .body-normal > .image {
  width: 100%;
  pointer-events: none;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  overflow: hidden;
}

.kakao-message > .wrapper > .right-section > .body > .message {
  padding: var(--space-base);
  word-wrap: break-word;
  white-space: pre-wrap;
}

.kakao-message > .wrapper > .right-section > .body > .button-wrapper {
  padding: var(--space-base);
  display: flex;
  flex-direction: column;
}

.kakao-message > .wrapper > .right-section > .body > .button-wrapper > .button {
  color: #000;
  text-align: center;
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-normal);
  border-radius: 9px;
  padding: 10px;
  margin-top: 5px;
  background-color: #f6f7f8;
}

.campaign-guide-wrapper {
  background-color: var(--color-cole-gray);
  font-size: var(--font-size-h6-base);
  color: var(--color-primary);
  padding: var(--space-base);
  border-radius: 9px;
}

.campaign-image-wrapper {
  width: 10rem;
  height: 10rem;
  overflow: hidden;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.campaign-report-modal-body {
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  max-height: calc(60vh - 4.3rem);
}

.campaign-report-blue-wrapper {
  background-color: var(--color-superUltraLightBlue);
  border-radius: 9px;
  padding: var(--space-base);
  margin-top: var(--space-base);
}

.campaign-report-wrapper {
  border-radius: 9px;
  padding: var(--space-base);
  background-color: var(--color-cole-gray);
  margin-top: var(--space-base);
}

.inner-container > .smartphone > .alimtalk {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
}

.smartphone > .alimtalk > .header {
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  background-color: #fee500;
  padding: var(--space-base);
  font-size: var(--font-size-h7-base);
  font-weight: var(--font-weight-bold);
}

.smartphone > .alimtalk > .image {
  width: 100%;
  -webkit-background-position: center;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  pointer-events: none;
}

.smartphone > .alimtalk > .body {
  padding: var(--space-base);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.smartphone > .alimtalk > .body > .button {
  margin-top: auto;
  background-color: var(--color-cole-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-h5-base);
  text-align: center;
  height: 50px;
  border-radius: 12px;
}

.basic-input {
  border: 1px solid var(--color-gray);
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #fff;
  min-height: 50px;
  font-family: "Pretendard-Regular", sans-serif !important;
  font-size: var(--font-size-h7-medium);
}

#shipping-line > .h6 {
  margin-top: var(--space-tiny);
}

.buttons-wrapper {
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.buttons-wrapper > .button {
  min-height: 50px;
  background-color: var(--color-cole-gray);
  color: var(--color-lightPrimary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: var(--font-size-h5-base);
  font-weight: var(--font-weight-bold);
}

.buttons-wrapper > .button:hover {
  background-color: var(--color-gray);
}

.buttons-wrapper > .button[aria-selected="true"] {
  background-color: var(--color-superUltraLightBlue);
  color: var(--color-blue);
}

.buttons-wrapper > .button[aria-disabled="true"] {
  background-color: var(--color-gray);
  pointer-events: none;
}

.guide-wrapper {
  border-radius: 9px;
  background-color: var(--color-cole-gray);
  font-size: var(--font-size-h6-base);
  color: var(--color-primary);
  padding: var(--space-base);
  width: fit-content;
}

.subicon-wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-superUltraLightBlue);
  border-radius: 12px;
}

.blacklist-badge {
  background-color: var(--color-lightRed);
  border-radius: 12px;
  padding: var(--space-tiny) var(--space-line);
  color: var(--color-red);
  font-size: var(--font-size-h7-base);
  font-weight: bold;
  width: fit-content;
}

.exchange-badge {
  padding: var(--space-tiny) var(--space-adjusted-line);
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: auto;
  background-color: var(--color-superUltraLightBlue);
  border-radius: 12px;
}

.nation-flag {
  width: 25px;
}

.channel-onboarding-guide-wrapper {
  border-radius: 12px;
  background-color: var(--color-superUltraLightBlue);
  padding: var(--space-line) var(--space-line) var(--space-adjusted-line)
    var(--space-line);
  margin-bottom: var(--space-medium);
}

.channel-growing-logo {
  width: 3.92rem;
  height: 3.92rem;
  pointer-events: none;
  border-radius: 9px;
}

.dot {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  &--blue {
    background-color: var(--color-blue);
  }
  &--light-blue {
    background-color: var(--color-lightBlue);
  }
  &--ultra-blue {
    background-color: var(--color-ultraLightBlue);
  }
  &--white {
    background-color: #fff;
  }
}

.channel-onboarding-guide-wrapper .progress-bar-wrapper {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  padding-left: 2px;
  background-color: #fff;
}

.channel-onboarding-guide-wrapper .progress-bar-wrapper > .progress-bar {
  height: 20px;
  background-color: var(--color-blue);
  border-radius: 20px;
}

#channel-onboarding .title {
  margin-top: var(--space-medium);
  margin-bottom: var(--space-base);
  font-size: var(--font-size-h4-base);
  font-weight: var(--font-weight-bold);
  width: 100%;
  max-width: 760px;
  text-align: left;
}

#channel-onboarding .block {
  background-color: #fff;
  border-radius: 9px;
  padding: var(--space-base);
  max-width: 760px;
  width: 100%;
}

#channel-onboarding .block > .block-comp {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-adjusted-line) var(--space-base);
  cursor: pointer;
}

#channel-onboarding .block > .block-comp:hover {
  background-color: var(--color-cole-gray);
}

.block > .block-comp > .icon {
  width: 44px;
  height: 44px;
  border-radius: 5px;
  background-color: var(--color-cole-gray);
  margin-right: var(--space-line);
}

.block-comp > .icon > img {
  pointer-events: none;
}

.block > .block-comp > .subtitle {
  flex: 1;
  font-size: var(--font-size-h6-medium);
  font-weight: var(--font-weight-bold);
}

.block > .block-comp > .buttons {
  padding-left: var(--space-line);
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.sync-description-box {
  background-color: var(--color-cole-gray);
  color: var(--color-primary);
  font-size: var(--font-size-h6-base);
  border-radius: 9px;
  padding: var(--space-adjusted-line);
  margin-top: var(--space-line);
}

.app-store-wrapper-landing {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--space-line);
}

.new-appstore-logo {
  width: 180px;
  height: 60px;
  cursor: pointer;
}

.new-appstore-logo-landing {
  width: 180px;
  height: 60px;
  cursor: pointer;
}

.new-appstore-logo:hover {
  opacity: 0.58;
}
.new-appstore-logo-landing:hover {
  opacity: 0.58;
}

.export-header-comp {
  padding: var(--space-base);
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.export-header-comp[aria-selected="true"] {
  background-color: var(--color-ultraLightBlue);
}
.export-header-comp:hover {
  background-color: var(--color-cole-gray);
}
.export-header-border {
  &-left {
    border-radius: 0px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }
  &-right {
    border-radius: 0px;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
  }
}
.shipping-notice-icon {
  width: 28px;
  height: 28px;
  margin-right: var(--space-base);
  pointer-events: none;
}

.retail-menu-plan {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.retail-menu-plan .search-section {
  width: 40%;
  padding-right: var(--space-line);
}

.retail-menu-plan .render-section {
  width: 60%;
  padding-left: var(--space-line);
}

.retail-product-tile {
  border-radius: 5px;
  border: 1px solid var(--color-gray);
  padding: var(--space-base);
  background-color: #fff;
  // cursor: pointer;
}

// .retail-product-tile:hover {
//   background-color: var(--color-cole-gray);
// }

.retail-product-tile:not(:first-child) {
  margin-top: var(--space-base);
}

.retail-product-tile > .prod-list-thumbnail {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 9px;
}

.retail-product-tile > .prod-list-no-thumbnail {
  width: 50px;
  height: 50px;
  background-color: var(--color-cole-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 9px;
}

.render-section > .retail-menu-board {
  border-radius: 5px;
  border: 1px solid var(--color-gray);
  padding: var(--space-base);
}

.render-section > .retail-menu-board > .menu-render {
  border-top: 1px solid var(--color-gray);
  margin-top: var(--space-line);
  padding-top: var(--space-line);
  min-height: 650px;
}

.retail-page-comp {
  border-radius: 12px;
  background-color: var(--color-cole-gray);
  padding: var(--space-tiny) var(--space-base);
  min-width: 100px;
  height: 44px;
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-lightPrimary);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.retail-page-comp[aria-selected="true"] {
  background-color: var(--color-superUltraLightBlue);
  color: var(--color-blue);
}

.red-notice {
  background-color: var(--color-superUltraLightRed);
  padding: var(--space-line);
  border-radius: 5px;
  font-size: var(--font-size-h6-medium);
}

@media (min-width: 320px) {
  .promotion-block:not(:first-child) {
    margin-top: var(--space-line);
  }
}

@media (min-width: 1121px) {
  .promotion-block:not(:first-child) {
    margin-top: 0;
  }
}

@media (max-width: 940px) {
  .report-stat-container {
    width: 50%;
  }
  .report-graph-container {
    padding-right: var(--space-line);
    margin-bottom: var(--space-line);
    &-w-50 {
      width: 100%;
    }
  }
}
@media (max-width: 780px) {
  .report-stat-container {
    width: 100%;
    padding-right: 0;
    margin-bottom: var(--space-line);
  }
  .location-sales-listItem {
    width: 100%;
    padding-right: var(--space-base);
  }
  .report-stat-wrapper > .header {
    display: block;
    flex-direction: column;
    align-items: flex-start;
  }
  .narrow-no-display {
    display: none;
  }
  #tag-mgmt .tag-width {
    width: 33.33%;
    position: relative;
    padding-right: var(--space-base);
    padding-bottom: var(--space-base);
  }
}

// END

// Around market

#mobile-layout {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  position: relative;
  background: #fff;
}

#mobile-layout .ui.sidebar {
  position: absolute;
  top: 0;
}

.mobile-sidebar-hider {
  z-index: 200;
  width: calc((100% - 480px) / 2);
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
}

#mobile-nav {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  position: fixed;
}

.p-relative {
  position: relative;
}

.mobile-top-container {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  position: relative;
}

.mobile-top {
  min-height: 2.857em;
  background: #fff;
  max-width: 480px;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mobile-item-wrapper {
  padding: 1em 0;
}

.around-title {
  font-size: 2.14em;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: 0.38px;
  color: #1a283e;
}

.around-header1 {
  font-size: 1.71em;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: 0.38px;
  color: #1a283e;
}

.around-subheader1 {
  font-size: 1.42em;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: 0.38px;
  color: #1a283e;
}

.around-subheader2 {
  font-size: 1.42em;
  font-weight: normal;
  line-height: 1.5em;
  letter-spacing: 0.38px;
  color: #1a283e;
}

.around-18Bold {
  font-size: 1.285em;
  font-weight: bold;
  font-family: "Pretendard-Regular", sans-serif;
  line-height: 1.2;
  letter-spacing: 0.38px;
  color: #1a283e;
}

.around-16 {
  font-size: 1.142em;
  font-weight: normal;
  line-height: 1.31em;
  letter-spacing: 0.38px;
  color: #1a283e;
}

.around-16Bold {
  font-size: 1.142em;
  font-weight: bold;
  line-height: 1.31em;
  letter-spacing: 0.38px;
  color: #1a283e;
}

.around-14 {
  font-size: 1em;
  font-weight: normal;
  line-height: 1.31em;
  letter-spacing: 0.38px;
  color: #1a283e;
}
.around-14Bold {
  font-size: 1em;
  font-weight: bold;
  line-height: 1.31em;
  letter-spacing: 0.38px;
  color: #1a283e;
}

.around-caption {
  font-size: 0.875em;
  font-weight: normal;
  line-height: 1.31em;
  letter-spacing: 0.38px;
  color: #1a283e;
}

.around-toggle-mod {
  margin-right: 10px;
  min-width: 17%;
  max-width: 17%;
  min-height: 120px;
  display: flex;
  flex-direction: row;
  border: 0.5px solid #838b96;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.38;
  }
}

.letter-spacing-1em {
  letter-spacing: 1em;
  margin-right: -1em;
}

.sidebar-header {
  background-color: #1a283e;
  min-height: 78px;
  padding: 15px 16px;
}

.sidebar-label {
  border: 2px solid #f6d662;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#login-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 15px 24px;
  height: 60px;
  box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 5px;
  font-size: 24px;
  color: #1a283e;
}

#search-input {
  padding: 10px 24px;
  height: 50px;
  box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 5px;
  font-size: 20px;
  color: #1a283e;
}

#search-input-full {
  padding: 0.714em 1.714em;
  height: 44px;
  background-color: #fff;
  font-size: 1em;
  color: #1a283e;
}

#basic-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.714em 1.07em;
  height: 44px;
  box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 5px;
  font-size: 1.142em;
  color: #1a283e;
}

#around-time-input {
  padding: 0.714em 1.07em;
  height: 44px;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 5px;
  font-size: 1.142em;
  color: #1a283e;
  font-weight: bold;
  // letter-spacing: 0.6em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 2.2em;
  // white-space: nowrap;
}

.around-header1 input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.footer-style {
  position: absolute;
  bottom: 0;
  background-color: #efeff4;
  padding: 10px 16px;
  // overflow-y: scroll;
}

#footer-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  padding: 5px 10px;
  text-align: center;
  cursor: "pointer";
}
#footer-button:hover {
  background-color: #d8d8d8;
}

#remove-appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.store-tile {
  background-color: #fff;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.around-basic-tile {
  background-color: #fff;
  border-bottom: 1px solid #d1d4d8;
}

#store-button {
  background-color: #fff;
  border-top: 1px solid #d1d4d8;
  height: 55px;
  width: 100%;
  border-radius: 0;
  margin-right: 0;
}
#store-button:hover:active:focus {
  background-color: #c6c9cf;
}

.quantity-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  &:focus {
    background: red;
  }
}

.quantity-input__modifier,
.quantity-input__screen {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}

.quantity-input__modifier {
  padding: 0.7em;
  width: 25%;
  font-size: 1.3em;
  background: #fff;
  color: #1a283e;
  border: 1px solid #d1d4d8;
  text-align: center;
  text-shadow: 0 1px 0 rgba(#fff, 0.6);

  cursor: pointer;

  &:hover {
    background: darken(#fff, 10%);
    color: darken(#1a283e, 20%);
  }

  &--left {
    border-radius: 9px 0 0 9px;
  }

  &--right {
    border-radius: 0 9px 9px 0;
  }
}

.quantity-input__modifier:disabled {
  opacity: 0.38;
}

.quantity-input__screen {
  width: 50%;
  padding: 0.7em;
  font-size: 1.3em;
  border: 0;
  border-top: 1px solid #d1d4d8;
  border-bottom: 1px solid #d1d4d8;
  text-align: center;
  inset: none;
}

.label-input__screen {
  width: 80%;
  padding: 0.7em;
  height: 60px;
  font-size: 1.5em !important;
  border-top: 1px solid #1a283e;
  border-bottom: 1px solid #1a283e;
  box-shadow: none !important;

  &--left {
    border-radius: 0 5px 5px 0;
    border-right: 1px solid #1a283e;
    border-left: 0;
    outline: none;
  }

  &--right {
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #1a283e;
    border-right: 0;
    outline: none;
  }
}

.label-input__modifier,
.label-input__screen {
  outline: none;
}

.label-input__modifier {
  padding: 0.7em;
  width: 20%;
  height: 60px;
  font-size: 1.5em !important;
  background: #fff;
  color: #1a283e;
  border: 1px solid #1a283e;
  text-align: center;

  &--left {
    border-radius: 5px 0 0 5px;
  }
  &--right {
    border-radius: 0 5px 5px 0;
  }
}

.store-modal-header-padding {
  padding: 7px 10px !important;
}

#store-modal-header-padding {
  padding-left: 20px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-right: 15px !important;
}

.around-sku-modal {
  width: 80%;
}

.simple-modal-close-wrapper {
  position: absolute;
  right: var(--space-base);
}

#button-group-underline-active {
  background-color: #fff;
  color: #1a283e;
  border-bottom: 1px solid #1a283e;
  border-radius: 0;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
}
#button-group-underline-inactive {
  background-color: #fff;
  color: #c6c9cf;
  border-bottom: 1px solid #c6c9cf;
  border-radius: 0;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
}

.store-product-block {
  height: 8em;
  padding: 0 !important;
}

.store-image {
  height: 8em;
  width: 8em;
  -o-object-fit: fill;
  object-fit: fill;
}

.store-logo {
  max-height: 3.25em;
  max-width: 3.25em !important;
  -o-object-fit: fill;
  object-fit: fill;
}

img.ui.image {
  -webkit-touch-callout: none !important;
  // -webkit-user-select: none !important;
}

input[type="date"],
input[type="time"] {
  -webkit-appearance: none;
}

.loyalty-image {
  border-radius: 5px;
  background-color: #c6c9cf;
  padding-bottom: 36%;
  padding-top: 36%;
  width: 7.7em;
  text-align: center;
}

.modal-loyalty-image {
  border-radius: 5px;
  background-color: #c6c9cf;
  padding-bottom: 25%;
  padding-top: 25%;
  width: 7.5em;
  text-align: center;
}

.item-image {
  height: 7.5em;
  width: 7.5em;
  -o-object-fit: fill;
  object-fit: fill;
}

.basic-border {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #1a283e;
}

.input-border {
  border-radius: 5px !important;
  border: 1px solid #1a283e !important;
}

.fix-bottom {
  position: absolute;
  padding-bottom: 2px;
  bottom: 0;
}

.bottom-divider {
  border-bottom: 1px solid #d1d4d8;
}

.right-divider {
  border-right: 1px solid #d1d4d8;
}

.order-status {
  padding: 1em 1em;
  border-radius: 9px;
  // display: flex;
  // align-items: center;
  text-align: center;
}

.checkbox-block {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-block input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 23px;
  width: 23px;
  background-color: #c6c9cf;
  border-radius: 2px;
}

.checkbox-block:hover input ~ .checkmark {
}

.selection-checkbox input:checked ~ .checkmark,
.checkbox-block input:checked ~ .checkmark {
  background-color: #1a283e;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.selection-checkbox input:checked ~ .checkmark:after,
.checkbox-block input:checked ~ .checkmark:after {
  display: block;
  transform: rotate(45deg) scale(1);
}

.selection-checkbox input:not(:checked) ~ .checkmark:after,
.checkbox-block input:not(:checked) ~ .checkmark:after {
  display: block;
  transform: rotate(45deg) scale(1);
}

.selection-checkbox .checkmark:after {
  transform: rotate(45deg) scale(1);
  opacity: 1;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
  left: 11px;
  top: 8px;
  width: 7px;
  height: 10px;
}

.checkbox-block .checkmark:after {
  transform: rotate(45deg) scale(1);
  opacity: 1;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
  left: 9px;
  top: 6px;
  width: 5px;
  height: 8px;
}

.checkbox-label {
  line-height: 20px;
  vertical-align: baseline;
  font-size: 16px;
  font-weight: bold;
  color: #1a283e;
}

.checkbox-label-small {
  line-height: 22px;
  vertical-align: baseline;
  font-size: 14px;
  font-weight: normal;
  color: #1a283e;
}

.around-subtext {
  padding-left: 34px;
  margin-top: 5px;
}

.around-divider {
  border-color: #1a283e !important;
}

.around-policy-block {
  height: 200px;
  overflow-y: scroll;
  background-color: #f9f9f9;
}

.around-policy-block-450 {
  height: 450px;
  overflow-y: scroll;
  background-color: #f9f9f9;
}

.cart-circle {
  position: absolute;
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  background-color: #f6d662;
  right: 18px;
  top: 10px;
  border: 1px solid #1a283e;
}

.around-cart-button {
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  position: fixed;
  border-radius: 50%;
  background-color: red;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 75px;
  bottom: 0;
  height: 75px;
  left: 40%;
  z-index: 1000;
  content: 1;
}

.around-side-footer-bottom {
  bottom: 0;
  background-color: #efeff4;
  flex-shrink: 0;
  padding: 10px 16px;
}

.around-main-button-bottom {
  bottom: 0;
  flex-shrink: 0;
  padding: 10px 16px;
}

.around-body-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.around-body-ele {
  flex: 1 0 auto;
}

#policy-table {
  width: 100%;
  border: 1px solid #1a283e;
  border-collapse: collapse;
}

#policy-table-row {
  border: 1px solid #1a283e;
}

#policy-table-header {
  border: 1px solid #1a283e;
}

.subscription-card {
  min-height: 21.5rem;
  width: 100%;
  padding: 2.125rem 1.5rem;
  background-color: #fff;
  border-radius: 5px;
}

// KAKAO

#channel-chat-button {
  position: fixed;
  z-index: 999;
  right: 1.2em; /* 화면 오른쪽으로부터의 거리, 숫자만 변경 */
  bottom: 2em; /* 화면 아래쪽으로부터의 거리, 숫자만 변경 */
}

.kakao-chat-icon {
  width: 144px;
  height: 64px;
}

//DATE PICKER

.custom-date-range-picker {
  height: 55px;
  border: 1px solid #d1d4d8;
  border-radius: 0;
  padding: 10px 20px;
}

// PRO SIDEBAR CUSTOM STYLE

.pro-sidebar .overlay {
  display: none !important;
}

.pro-sidebar .pro-sidebar-inner .pro-sidebar-layout {
  &::-webkit-scrollbar {
    display: none;
  }
}

.pro-sidebar-header {
  padding: 1.5rem 1rem;
  // text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pro-sidebar-header > .header-closer {
  position: absolute;
  top: 2px;
  right: 5px;
  font-size: 28px;
  cursor: pointer;
  padding: 0 0.314rem;
  border-radius: 5px;
  &:hover {
    color: #40516f;
  }
}

.pro-inner-item:before {
  content: "";
  display: none !important;
  width: 4px;
  min-width: 4px;
  height: 4px;
  border: 1px solid #131d2c;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
  box-shadow: 1px 0px 0px #fff, 0px -1px 0px #fff, 0px 1px 0px #fff,
    -1px 0px 0px #fff;
}

// SMS CAMPAIGN LIST
.list-min-height {
  padding: 0.78571429em;
  line-height: 1em;
  min-height: 2.71428571rem;
}

.ddd {
  background-color: #1a283e;
}
$sidebar-bg-color: #1a283e;
$submenu-bg-color: #131d2c;
$submenu-bg-color-collapsed: #131d2c !important;
$sidebar-color: #fff;
$highlight-color: #40516f;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;
$sidebar-width: 240px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 1024px !default;
$breakpoint-xl: 1200px !default;

.top-nav-container {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px 0 var(--space-line);
  box-shadow: rgb(100 116 139 / 12%) 0px 1px 4px;
  box-sizing: border-box;
  z-index: 10;
  border-bottom: 1px solid var(--color-cole-gray);
}

.top-nav-container {
  .sidebar-toggle-wrapper {
    margin-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    visibility: hidden;
    transition: ease 0.3s;
    position: relative;
    left: -200px;
  }
  .sidebar-toggle-wrapper.visible {
    visibility: visible;
    left: 0;
  }
}
.top-nav-text {
  margin-left: 3px;
  font-weight: bold;
}

.btn-toggle {
  cursor: pointer;
  display: flex;
  padding: 0.314rem;
  border-radius: 5px;
  font-size: 30px;
  &:hover {
    background-color: #efeff4;
  }
}

@import "~react-pro-sidebar/dist/scss/styles.scss";

// Onboarding

.onboarding-container {
  max-width: 780px;
  margin: 0 auto !important;
  height: 100%;
}

.onboarding-container .landing {
  padding: var(--space-medium) 0;
  text-align: center;
}

.onboarding-step-container {
  padding: var(--space-small);
  height: 100%;
  width: 100%;
}

.onboarding-navbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 0 var(--space-line) 0;
  border-bottom: 1px solid var(--color-primary);
  margin: 0 0 var(--space-line) 0;
}

.onboarding-step-container .split {
  display: flex;
}
.onboarding-step-container .split .left {
  flex: 0.5;
  padding-right: var(--space-medium);
  border-right: 1px solid var(--color-gray);
}

.onboarding-step-container .split .right {
  flex: 0.5;
  padding: 0 var(--space-line);
}

.onboarding-receipt-border {
  border: solid 0.5px #d1d4d8;
  margin: var(--space-base);
}

.onboarding-selection-box {
  border: 1px solid var(--color-gray);
  border-radius: 5px;
  background-color: #fff;
  width: 100%;
  padding: var(--space-line);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selection-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selection-checkbox input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.onboarding-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #c6c9cf;
  border-radius: 2px;
}

.onboarding-radio-box {
  border-radius: 5px;
  border: 1px solid var(--color-gray);
  padding: var(--space-base) var(--space-line);
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.onboarding-radio-box .radio-left {
  flex: 0.7;
}

.onboarding-radio-box .radio-right {
  flex: 0.3;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.onboarding-blue-button {
  border: 0px;
  background-color: var(--color-blue);
  font-size: var(--font-size-h5-base);
  color: #fff;
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.onboarding-blue-button:hover {
  background-color: var(--color-lightBlue);
}

.onboarding-blue-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.onboarding-blue-outline-button {
  background-color: #fff;
  border: 2px solid var(--color-blue);
  font-size: var(--font-size-h5-base);
  color: var(--color-blue);
  font-weight: var(--font-weight-bold);
  min-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.onboarding-blue-outline-button:hover {
  background-color: var(--color-lightBlue);
}

.onboarding-blue-outline-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.onboarding-blue-nofill-button {
  background-color: transparent;
  text-decoration: none;
  border: 0px;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-blue);
  font-size: var(--font-size-h5-base);
  font-weight: var(--font-weight-bold);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.onboarding-blue-nofill-button:hover {
  color: var(--color-lightBlue);
}

.onboarding-blue-nofill-button[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.4;
}

.g-input {
  border: 1px solid var(--color-gray);
  padding: 5px 15px;
  background-color: #fff;
  font-family: "Pretendard-Regular", sans-serif !important;
  font-size: var(--font-size-h6-medium);
  border-radius: 5px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1) inset;
  min-height: 55px;
}

.g-input:hover,
:focus {
  outline: none !important;
}

.g-textArea {
  border: 1px solid var(--color-gray);
  padding: 5px 15px;
  background-color: #fff;
  font-family: "Pretendard-Regular", sans-serif !important;
  font-size: var(--font-size-h6-medium);
  border-radius: 5px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1) inset;
  min-height: 150px;
}

.g-textArea:hover,
:focus {
  outline: none !important;
}

.category-filter-search-wrapper {
  border: 1px solid var(--color-gray);
  padding: 5px 15px;
  background-color: #fff;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.custom-table-search-wrapper {
  border-top: 1px solid var(--color-gray);
  border-left: 1px solid var(--color-gray);
  border-right: 1px solid var(--color-gray);
  border-bottom: none;
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #fff;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.custom-table-search-input {
  border: none;
  background-color: #fff;
  margin-left: var(--space-base);
  width: 100%;
  min-height: 40px;
  font-family: "Pretendard-Regular", sans-serif !important;
  font-size: var(--font-size-h7-medium);
}

.custom-table-wrapper i {
  position: absolute;
}

.custom-table-empty {
  background-color: #fff;
  padding: var(--space-line) var(--space-large);
  text-align: center;
  font-size: var(--font-size-h5-medium);
  color: var(--color-gray);
}

.input-with-button {
  border-right: none;
  border-left: none;
  border-radius: 0;
  height: 60px;
  border-top: 1px solid var(--color-gray);
  border-bottom: 1px solid var(--color-gray);
  padding: var(--space-base) var(--space-adjusted-line);
  background-color: #fff;
  font-size: var(--font-size-h6-medium);
  width: 100%;
}

.input-wrapper .input-label {
  margin-bottom: var(--space-base);
}

.onboarding-step-container .custom-dropdown {
  cursor: pointer;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  outline: 0;
  transform: rotateZ(0);
  min-width: 14em;
  min-height: 2.71428571em;
  background: #fff;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  height: 55px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.onboarding-step-container .custom-dropdown .search {
  height: 55px;
}

.onboarding-step-container .ui.selection.dropdown > .dropdown.icon {
  margin: 0;
}

// Survey

.text-survey {
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-family: "Pretendard-Regular", sans-serif;
  line-height: 1.13;
  letter-spacing: 0.36px;
  color: #1a283e;
}

.sv_main.sv_default_css .sv_p_root > .sv_row:nth-child(2n) {
  background-color: #fff;
}

.sv_main input[type="button"],
.sv_main button {
  font-size: 1em !important;
  padding: 0.5em 2em !important;
}

// PhoneAlertModal

.phoneAlertModal_container {
  max-width: 320px;
  border-radius: 20px !important;
  padding: 5px;
}

.phoneAlertModal_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 90%;
  margin: 0 auto;
}

.phoneAlertModal_title {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: #1a283e;
}

.phoneAlertModal_desc {
  width: 80%;
  font-size: 18px;
  padding: 20px 0 30px 0;
  margin: 0 auto;
  white-space: pre-wrap;
  text-align: center;
  color: #1a283e;
}

.phoneAlertModal_button {
  width: 100%;
  background-color: #1a283e;
  color: #fff;
  padding: 20px 30px;
  border-radius: 40px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

// NeedUpdateModal
.needUpdateModal_container {
  max-width: 380px;
  border-radius: 20px !important;
  padding: 5px;
}

.needUpdateModal_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.needUpdateModal_desc {
  width: 90%;
  padding: 15px 0 25px 0;
  margin: 0 auto;
  white-space: pre-wrap;
}

.needUpdateModal_button {
  width: 90%;
  padding: 20px 30px;
  border-radius: 40px;
  margin-bottom: 20px;
}

// 404
.forbidden-container {
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  transform: translateY(-35%);
  text-align: center;
}

.forbidden-title {
  font-size: 200px;
}

.forbidden-subtitle {
  font-size: var(--font-size-h3-medium);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-line);
}

.forbidden-text {
  font-size: var(--font-size-h5-base);
  font-weight: var(--font-weight-normal);
}

// label-template
.label-guide-container {
  display: flex;
  flex-wrap: wrap;
}

.label-guide-wrapper {
  flex: 0.33;
  padding: var(--space-line);
  border-radius: 5px;
  background-color: #fff;
  min-height: 18rem;
  max-width: 20rem;
  box-shadow: 0 0 5px rgba(#000, 0.1);
}

.label-guide-wrapper:not(:first-child) {
  margin-left: var(--space-small);
}

.label-guide-wrapper > .title {
  font-size: var(--font-size-h5-base);
  font-weight: var(--font-weight-bold);
}

.label-guide-wrapper > .description {
  font-size: var(--font-size-h7-medium);
  font-weight: var(--font-weight-normal);
  margin-top: var(--space-base);
}

.label-guide-wrapper > .action {
  margin-top: var(--space-line);
}

.label-page-setting-container {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
}

.label-page-setting-container > .item {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-small);
}

.label-custom-design-container {
  width: 100%;
  margin-top: var(--space-line);
  display: flex;
  flex-wrap: wrap;
}

.label-custom-design-container > .item {
  margin: var(--space-line) 0;
  width: 50%;
  // padding: 0 var(--space-base);
}

.label-custom-design-container > .item:nth-child(odd) {
  padding-right: var(--space-line);
}
.label-custom-design-container > .item:nth-child(even) {
  padding-left: var(--space-line);
}

.label-custom-design-container > .item:hover {
  opacity: 1;
}

.label-custom-design-container > .item > .input-text {
  display: flex;
  align-items: flex-start;
}

.label-preview-container {
  border: 1px solid var(--color-gray);
  border-radius: 5px;
}

.label-preview-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label-preview-wrapper .text {
  width: 100%;
  white-space: normal;
  overflow: hidden;
  line-height: 1.2;
}

.label-preview-wrapper .image {
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
}

.label-coppied-wrapper {
  background-color: var(--color-cole-gray);
  padding: var(--space-base) var(--space-line);
  border-radius: 5px;
  margin: var(--space-line) 0;
  font-size: var(--font-size-h6-base);
  color: var(--color-blue);
  // font-weight: var(--font-weight-bold);
}

.fit-parent-width {
  width: 100%;
  height: auto;
}

.fit-parent-height {
  height: 100%;
  width: auto;
}

.print-label-selected {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.print-template-comp {
  width: 100%;
  border-bottom: 1px solid #d1d4d8;
  border-top: none;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

// .print-template-comp:hover {
//   background-color: var(--color-cole-gray);
// }

.print-template-comp > .left {
  padding: var(--space-base) var(--space-line);
  width: 100%;
  height: 100%;
}
.print-template-comp > .left:hover {
  background-color: var(--color-cole-gray);
}
.print-template-comp > .right {
  padding: 0 var(--space-line);
}

.label-guide-product-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.action-container-column {
  width: 25%;
}

.require-doc-modal {
  width: 60% !important;
}
.receipt-modal-wrapper {
  width: 75% !important;
}
.account-modal-wrapper {
  width: 60% !important;
}

.product-filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.purchase-note-wrapper {
  border: 1px solid var(--color-gray);
  padding: 5px;
  width: 100%;
  margin-top: var(--space-adjusted-line);
}

.purchase-modal-inner-wrapper {
  width: 75% !important;
}

.purchase-edit-quantity-modal-wrapper {
  width: 50% !important;
  min-width: none;
}
.settings-store-info-wrapper {
  display: flex;
  gap: var(--space-adjusted-line);
  margin-top: var(--space-adjusted-line);
}

.custom-text-field-medium {
  width: 45%;
}

.custom-text-field-small {
  width: 30%;
}

.custom-text-field-large {
  width: 70%;
}

.settings-logo-wrapper {
  display: flex;
  align-items: center;
}

.settings-logo-wrapper .logo {
  width: 50%;
}

.settings-logo-wrapper .upload {
  width: 50%;
}

.settings-industry-type {
  min-width: 20em;
}
.settings-card-modal-wrapper {
  width: 50% !important;
}

.card-modal-input-wrapper {
  display: flex;
  justify-content: center;
}

.card-modal-input-wrapper .item {
  width: 50%;
}

.responsive-modal-wrapper {
  width: 50% !important;
}

.customer-filter-wrapper {
  // display: flex;
  // align-items: flex-end;
}
.responsive-grid-cell {
  min-width: 150px;
}

@media only screen and (max-width: 767px) {
  .ui.form .fields {
    margin-bottom: 1em;
  }
}

// app market
.responsive-wrapper {
  margin: var(--space-small);
}

.app-market-info-wrapper {
  display: flex;
}

.app-market-info-wrapper .desc-wrapper {
  width: 70%;
}

.app-market-info-wrapper .desc-wrapper .icon {
  width: 20%;
}

.app-market-info-wrapper .desc-wrapper .title {
  width: 80%;
}

.app-market-info-wrapper .btn-wrapper {
  width: 30%;
  display: flex;
  padding: var(--space-line);
  flex-direction: column;
  gap: var(--space-adjusted-line);
  align-items: flex-start;
}

.app-market-info-wrapper .info-border {
  height: auto;
  border-left: 1px solid var(--color-gray);
  margin-left: var(--space-line);
  margin-right: var(--space-line);
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.co-order-info-wrapper {
  display: flex;
  width: 100%;
}

.co-order-info-wrapper .first {
  border-right: 1px solid var(--color-gray);
  flex: 0.5;
  flex-wrap: wrap;
  padding-right: var(--space-line);
}

.co-order-info-wrapper .second {
  padding-left: var(--space-line);
  flex: 0.5;
  flex-wrap: wrap;
}

.coupon-table {
  max-width: 1200px !important;
}

.kakao-chat-dropdown-wrapper {
  display: flex;
  align-items: flex-end;
  gap: var(--space-base);
}

.daily-report-modal-wrapper {
  width: 63% !important;
}

.daily-report-modal-wrapper .daily-report-modal-body-wrapper {
  margin: 0 15%;
}

.contract-alert-modal-wrapper {
  width: 35% !important;
}

.contract-alert-modal-wrapper > .bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: var(--space-line);
}

.radio-with-text-wrapper {
  cursor: pointer;
}

.narrow-switch-report-basis-wrapper {
  min-width: 100px;
  height: 55px;
  max-width: 40%;
  border-radius: 10px !important;
}

.switch-report-basis-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--space-line);
  height: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  gap: 20px;
  border-radius: 5px;
  margin-left: var(--space-base);
}

// supplier

.create-modal-subtitle {
  border-bottom: 1px solid var(--color-primary);
}

.create-modal-switch-wrapper {
  padding: 1.2rem 1rem;
  background-color: #efeff4;
}

// stock history
.history-modal-edit-alert {
  background-color: var(--color-cole-gray);
  border-radius: 5px;
  padding: var(--space-adjusted-line) var(--space-line);
  margin-bottom: var(--space-adjusted-line);
}

// pos location
.pos-location-info-caution-wrapper {
  padding: var(--space-adjusted-line) var(--space-line);
  border-radius: 12px;
  background-color: var(--color-superUltraLightBlue);
}

.coupon-container {
  width: 163px;
  border-radius: 5px;
  background-color: #fff;
  border: 0.5px solid var(--color-cole-lightPrimary);
}

.coupon-container .coupon-top-wrapper {
  padding: var(--space-base);
  display: flex;
  justify-content: center;
  align-items: center;
}

.coupon-container .coupon-bottom-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 var(--space-base) var(--space-base) var(--space-base);
}

.coupon-container .coupon-code-wrapper {
  margin-top: var(--space-base);
  background-color: #fff;
  width: auto;
  border-radius: 5px;
  padding: 5px var(--space-base);
}

.coupon-upload-notice-wrapper {
  background-color: var(--color-cole-gray);
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  border-radius: 9px;
  padding: var(--space-line);
}

.coupon-label-for-upload {
  background-color: var(--color-cole-gray);
  padding: var(--space-adjusted-line) var(--space-line)
    var(--space-adjusted-line) var(--space-base);
  color: var(--color-blue);
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  border-radius: 9px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coupon-label-for-upload:hover {
  background-color: var(--color-gray);
}

.coupon-label-for-upload[aria-selected="true"] {
  background-color: var(--color-blue);
  color: #fff;
}

.coupon-label-for-upload:not(:first-child) {
  margin-top: var(--space-adjusted-line);
}

.coupon-barcode-area-for-upload {
  height: 350px;
  width: 100%;
  padding: var(--space-base);
  border: 1px solid var(--color-gray);
  border-radius: 5px;
}

.coupon-date-picker {
  height: 100%;
  width: 100%;
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-blue);
  border: none;
  padding: var(--space-base) var(--space-line);
}

.qty-big-wrapper {
  height: 50px;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--color-gray);
  border-radius: 5px;
}

.qty-big-wrapper .remove-comp {
  height: 50px;
  width: 50px;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--color-gray);
  border-bottom: 1px solid var(--color-gray);
  border-top: 1px solid var(--color-gray);
}

.qty-big-wrapper .add-comp {
  height: 50px;
  width: 50px;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--color-gray);
  border-top: 1px solid var(--color-gray);
  border-bottom: 1px solid var(--color-gray);
}

.qty-big-wrapper .qty-input {
  border: none !important;
}

.qty-with-comp-wrapper {
  padding-top: var(--space-tiny);
  display: flex;
  align-self: center;
  justify-content: space-between;
}

.sortable-drag-handle {
  width: 20px;
  background-color: var(--color-ultraLightPrimary);
  opacity: 0.3;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.5;
  }
}

.drag-icon-wrapper {
  width: 30px;
  height: 30px;
  background-color: var(--color-cole-gray);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sku-info-block {
  flex: 1;
}

.menu-plan-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-left: var(--space-tiny);
}

.content-container {
  background-color: var(--color-cole-gray);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100vw;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
}

.content-container > .white-container {
  background-color: #fff;
  flex: 1;
  padding: var(--space-medium) var(--space-small);
}

// library

.mui-hide-arrow {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

[class^="MuiTableCell-root"] {
  min-width: 130px;
}

.coupon-table tr > th:nth-child(1)[class^="MuiTableCell-root"] {
  min-width: auto;
}
.coupon-table tr > th:nth-child(2)[class^="MuiTableCell-root"] {
  min-width: auto;
}
.coupon-table tr > td:nth-child(1)[class^="MuiTableCell-root"] {
  min-width: auto;
}
.coupon-table tr > td:nth-child(2)[class^="MuiTableCell-root"] {
  min-width: auto;
}
.product-table tr > th:nth-child(1)[class^="MuiTableCell-root"] {
  min-width: auto;
}
.product-table tr > td:nth-child(1)[class^="MuiTableCell-root"] {
  min-width: auto;
}

// Barcode Coupon
.barcode-coupon-wrapper {
  width: 270px;
  height: 293px;
  padding: 14px 10px;
  background-color: #fff;
}

.barcode-coupon-wrapper .gifticon-image-wrapper {
  width: 50%;
  height: 125px;
  padding: 10px;
  overflow: hidden;
  aspect-ratio: 1/1;
}

.barcode-coupon-wrapper .gifticon-image-wrapper .image-style {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 9px;
}

.barcode-coupon-wrapper .usable-info-wrapper {
  width: 100%;
  height: 60px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.barcode-coupon-wrapper .barcode-wrapper {
  width: 100%;
  height: 80px;
  padding: 5px 20px;
}

.barcode-coupon-wrapper .store-info-wrapper {
  width: 100%;
  height: 100px;
  padding: 10px;
}

.barcode-retrieve-box {
  background-color: var(--color-cole-gray);
  padding: var(--space-base) var(--space-line);
  border-radius: 12px;
  margin-bottom: var(--space-small);
  font-size: var(--font-size-h6-base);
}

.around-customer-store-container {
  margin-top: var(--space-small);
}

.around-customer-store-container > .store-wrapper {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  background-color: #fff;
  padding: var(--space-line) var(--space-line);
}

.around-customer-store-container > .store-list-wrapper {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  background-color: #fff;
  padding: var(--space-base) var(--space-base);
}

.store-wrapper > .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--space-line);
}

.store-wrapper > .header > .point {
  font-size: var(--font-size-h3-medium);
  font-weight: var(--font-weight-bold);
}

.store-wrapper > .header > .lastVisit {
  font-size: var(--font-size-h7-base);
  font-weight: var(--font-weight-normal);
}

.store-label-wrapper {
  background-color: var(--color-ultraLightBlue);
  border-radius: 5px;
  padding: var(--space-tiny) var(--space-base);
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  margin-top: var(--space-line);
  color: var(--color-blue);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.store-info {
  width: 50%;
  padding-right: var(--space-line);
}

.store-info > .business-hour {
  font-size: var(--font-size-h7-base);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.around-customer-info-container {
}

.around-customer-info-container > .info-wrapper {
  // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  background-color: #fff;
  padding: var(--space-line) var(--space-line);
}

.info-wrapper .info-label {
  width: 50%;
  padding-right: var(--space-base);
}

.info-label > .label {
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-normal);
}

.info-label > .value {
  font-size: var(--font-size-h5-base);
  font-weight: var(--font-weight-bold);
}

.around-store-logo {
  max-height: 55px;
  max-width: 55px;
  border-radius: 9px;
  -o-object-fit: fill;
  object-fit: fill;
}

.responsive-drop-down {
  border: 1px solid var(--color-primary) !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding-right: var(--space-adjusted-line) !important;
}

.ui.selection.dropdown .fit-width {
  min-width: 12em !important;
}

.ui.search.selection.dropdown > input.search {
  line-height: 1rem !important;
  padding: 0.5em 2.1em 0.5em 1em !important;
  min-height: 40px !important;
}

.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0;
  right: 1em;
  z-index: 1;
  margin: -0.3em;
  margin-left: auto;
  padding: 0.91666667em;
  opacity: 0.8;
  transition: opacity 0.1s ease;
}

.responsive-drop-down > .dropdown.icon {
  cursor: pointer;
  position: relative !important;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  // top: 0;
  right: 0 !important;
  // z-index: 1;
  // margin: -0.3em;
  margin-left: auto;
  padding: 0.91666667em;
  opacity: 0.8;
  transition: opacity 0.1s ease;
}

.custom-drop-down {
  border: 1px solid var(--color-gray) !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding-right: 15px !important;
  padding-right: var(--space-adjusted-line) !important;
  min-width: 8rem !important;
}

.custom-drop-down > .dropdown.icon {
  cursor: pointer;
  position: relative !important;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  right: 0 !important;
  margin-left: auto;
  padding: 0.91666667em;
  opacity: 0.8;
  transition: opacity 0.1s ease;
}

.ui.search.selection.dropdown > input.search {
  line-height: 1.21428571em;
  padding: 0.67857143em 2.1em 0.67857143em 1em;
  min-height: 50px;
}

.reponsive-date-range-picker {
  border: 1px solid var(--color-primary) !important;
  border-radius: 5px !important;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: var(--color-primary) !important;
}

@media (max-width: 1600px) {
  .responsive-drop-down {
    min-width: 10em !important;
  }
}

@media (max-width: 1200px) {
  .responsive-drop-down {
    min-width: auto !important;
  }
}

// around end

@media (min-width: 1292px) {
  .signLeft {
    width: 35%;
    overflow-y: scroll;
  }
  .signRight {
    width: 65%;
  }
  .prod-editor-left {
    width: 80%;
  }
  .prod-editor-right {
    width: 20%;
    padding-left: 1.4em;
  }
  .mtable-search-width {
    min-width: 450px !important;
  }
  .home-image {
    width: 500px;
    height: 430px;
  }
  .stat-item {
    width: 25%;
    padding: var(--space-base);
  }
}

@media (min-width: 1024px) and (max-width: 1291.98px) {
  .signLeft {
    width: 35%;
    overflow-y: scroll;
  }
  .signRight {
    width: 65%;
  }
  .prod-editor-left {
    width: 80%;
  }
  .prod-editor-right {
    width: 20%;
    padding-left: 1.4em;
  }
  .mtable-search-width {
    min-width: 450px;
  }
  .mtable-search-width {
    min-width: 150px !important;
  }
  .home-image {
    width: 500px;
    height: 430px;
  }
  .stat-item {
    width: 25%;
    padding: var(--space-base);
  }
}

@media (min-width: 718px) and (max-width: 1023.98px) {
  .signLeft {
    width: 35%;
    overflow-y: scroll;
  }
  .signRight {
    width: 65%;
  }
  .prod-editor-left {
    width: 100%;
  }
  .prod-editor-right {
    width: 100%;
  }
  .mtable-search-width {
    min-width: 150px !important;
  }
  .home-image {
    width: 500px;
    height: 430px;
  }
  .recover-dialog {
    width: 80%;
  }
  .drop-out {
    width: 50% !important;
  }
  .stat-item {
    width: 50%;
    padding: var(--space-base);
  }
}

@media (min-width: 480px) and (max-width: 717.98px) {
  .signLeft {
    width: 100%;
    overflow-y: scroll;
  }
  .signRight {
    display: none !important;
  }
  .prod-editor-left {
    width: 100%;
  }
  .prod-editor-right {
    width: 100%;
  }
  .mtable-search-width {
    min-width: 150px !important;
  }
  .home-image {
    width: 400px;
    height: 344px;
  }
  .recover-dialog,
  .drop-out {
    width: 90% !important;
  }
  .drop-out-bottom {
    margin-bottom: var(--space-base);
  }
  .stat-item {
    width: 50%;
    padding: var(--space-base);
  }
}

// responsive text

@media (min-width: 1680) {
  .h5 {
    font-size: var(--font-size-h5-large);
    line-height: var(--line-height-h5-medium);
  }
}

@media (max-width: 1292px) {
  .h5 {
    font-size: var(--font-size-h5-medium);
    line-height: var(--line-height-h5-medium);
  }
}

@media (max-width: 480px) {
  .h5 {
    font-size: var(--font-size-h5-base);
    line-height: var(--line-height-h5-base);
  }
}

// Align adjustment
@media (min-width: 150px) {
  .stock-stat-comp {
    padding: 10px 15px;
    width: 100%;
    min-height: 8rem;
  }
}

@media (min-width: 480px) {
}

@media (min-width: 718px) {
  .stock-stat-comp {
    padding: 10px 15px;
    width: 50%;
    min-height: 8rem;
  }
}

@media (min-width: 1025px) {
  .sidebar-collapsed .report-tile .sidebar-collapsed .stat-wrapper {
    width: auto !important;
  }
  .sidebar-collapsed.content-container {
    width: calc(100vw);
  }
  .content-container {
    width: calc(100vw - 240px);
  }
  .report-tile,
  .stat-wrapper {
    width: 73vw !important;
  }
  .sidebar-collapsed .responsive-custom-table-wrapper {
    width: auto !important;
  }
  .responsive-custom-table-wrapper {
    width: 73vw !important;
  }
  .sidebar-collapsed .responsive-btn-wrapper {
    width: auto !important;
  }
  .responsive-btn-wrapper {
    width: 73vw !important;
  }

  .stock-stat-comp {
    padding: 10px 15px;
    width: 33%;
    min-height: 8rem;
  }
}

@media (min-width: 1280px) {
  .dashboard-panel {
    &-left {
      padding: 1rem 1rem 1rem 0;
    }
  }
  .dashboard-panel-wrapper {
    padding: 1.5em 0em;
  }
  .reports-panel-width-66 {
    width: 66%;
  }
  .reports-panel-width-33 {
    width: 33%;
  }
  .reports-panel-width-50 {
    width: 50%;
  }
}

@media (min-width: 30em) and (max-width: 1279px) {
  .dashboard-panel {
    &-left {
      padding: 1.2em 0 0;
    }
  }
  .dashboard-panel-wrapper {
    padding: 1.5em 2.5em;
  }
  .reports-panel-width-66 {
    width: 100%;
  }
  .reports-panel-width-33 {
    width: 100%;
  }
  .reports-panel-width-50 {
    width: 100%;
  }
}

@media (min-width: 30em) and (max-width: 718px) {
  .dashboard-panel-wrapper {
    padding: 1.5em 1em;
  }
}

@media (max-width: 30em) {
  .dashboard-panel-wrapper {
    padding: 1em;
  }
}

@media (max-width: 1292px) {
  .stock-report-stat-comp {
    width: 50%;
    padding: var(--space-line) var(--space-base);
  }
}

@media (max-width: 1024px) {
  .top-nav-container {
    height: 60px;
    padding: 0 var(--space-line);
  }
  .account-dropdown-container {
    right: 28px;
  }
  .top-nav-text {
    margin-bottom: -3px;
    font-size: var(--font-size-h6-medium);
  }
  .btn-toggle {
    display: flex;
    margin-right: auto;
  }
  .pro-popper {
    display: block;
  }
  .report-alert-box {
    width: calc(100% - 20px);
  }
  .require-doc-modal {
    width: 85% !important;
  }
  .product-button-wrapper {
    .primary-button,
    .primary-outline-button {
      font-size: var(--font-size-h7-base);
    }
  }
  .product-filter-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-line);
  }
  .purchase-modal-inner-wrapper {
    width: 80% !important;
  }
  .settings-card-modal-wrapper {
    width: 80% !important;
  }
  .message-report-box {
    width: 50%;
  }
  .kakao-chat-dropdown-wrapper {
    flex-direction: column;
    align-items: stretch;
  }
  .daily-report-modal-wrapper {
    width: 70% !important;
  }

  .daily-report-modal-wrapper .daily-report-modal-body-wrapper {
    margin: 0 15%;
  }
  .contract-alert-modal-wrapper {
    width: 50% !important;
  }
}

@media (max-width: 718px) {
  .report-two-item-wrapper {
    flex-direction: column;
  }

  .report-two-item-wrapper > .item {
    width: 100%;
  }

  .reports-panel {
    margin-right: 0;
  }

  .report-nav-wrapper .report-nav {
    font-size: 2vw;
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .location-modal-body {
    max-height: 50vh;
  }
  .settings-store-info-wrapper {
    flex-direction: column;
  }

  .custom-text-field-medium {
    width: 60%;
  }

  .custom-text-field-small {
    width: 60%;
  }

  .custom-text-field-large {
    width: 60%;
  }
  .card-modal-input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--space-adjusted-line);
  }
  .card-modal-input-wrapper .item {
    width: 100%;
  }

  .app-market-info-wrapper {
    flex-direction: column;
  }

  .app-market-info-wrapper .desc-wrapper {
    width: 100%;
  }
  .app-market-info-wrapper .btn-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: var(--space-base);
    padding-bottom: var(--space-base);
  }
  .app-market-info-wrapper .info-border {
    width: 100%;
    // height: auto;
    border-left: none;
    border-bottom: 1px solid var(--color-gray);
    margin-left: 0;
    margin-top: var(--space-line);
    margin-right: 0;
    margin-bottom: var(--space-line);
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .responsive-modal-wrapper {
    width: 100% !important;
  }
  .customer-filter-wrapper {
    flex-direction: column;
    gap: var(--space-base);
    align-items: flex-start;
  }
  .responsive-hidden {
    visibility: hidden;
  }
  .responsive-none {
    display: none;
  }
  .co-order-info-wrapper {
    flex-direction: column;
  }

  .co-order-info-wrapper .first {
    border-bottom: 1px solid var(--color-gray);
    border-right: none;
    flex: 1;
    flex-wrap: wrap;
    padding-right: 0;
    padding-bottom: var(--space-line);
  }

  .co-order-info-wrapper .second {
    padding-left: 0;
    padding-top: var(--space-line);
    flex: 1;
    flex-wrap: wrap;
  }

  .require-doc-modal {
    width: 95% !important;
  }
  .receipt-modal-wrapper {
    width: 95% !important;
  }
  .account-modal-wrapper {
    width: 90% !important;
  }
  .responsive-wrapper {
    margin: var(--space-line);
  }
  .daily-report-modal-wrapper {
    width: 90% !important;
  }

  .daily-report-modal-wrapper .daily-report-modal-body-wrapper {
    margin: 0 5%;
  }
  .contract-alert-modal-wrapper {
    width: 70% !important;
  }
}

@media (max-width: 479.98px) {
  .signLeft {
    width: 100%;
    overflow-y: scroll;
  }
  .signRight {
    display: none !important;
  }
  .prod-editor-left {
    width: 100%;
  }
  .prod-editor-right {
    width: 100%;
  }
  .mtable-search-width {
    min-width: 150px !important;
    max-width: 80%;
  }
  .home-image {
    width: 350px;
    height: 301px;
  }
  .recover-dialog,
  .drop-out {
    width: 90% !important;
  }
  .drop-out-bottom {
    margin-bottom: var(--space-base);
  }
  .pincode-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 25%;
    padding: 0 20px;
  }
  .pincode-input-wrapper {
    gap: 6px;
  }
  .pincode-input {
    font-size: 20px !important;
  }
  .pincode-sub-input {
    width: 60px;
    height: 80px;
  }
  .pincode-error-wrapper {
    width: 100% !important;
    padding: 0.7em 1.5em !important;
  }
  .pincode-button {
    padding: 0.78571429em 1.5em 0.78571429em !important;
    margin-bottom: 10px !important;
  }
  .around-toggle-mod {
    min-width: 30%;
    max-width: 30%;
    min-height: 120px;
  }
  .custom-text-field-medium {
    width: 90%;
  }
  .custom-text-field-small {
    width: 90%;
  }
  .custom-text-field-large {
    width: 90%;
  }
  .settings-logo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--space-base);
  }
  .settings-logo-wrapper .logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .settings-logo-wrapper .upload {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .settings-logo-wrapper .upload .primary-button {
    width: 100%;
  }
  .settings-industry-type {
    min-width: 15em;
  }
  .settings-card-modal-wrapper {
    width: 100% !important;
  }
  .responsive-wrapper {
    margin: var(--space-base);
  }
  .app-market-info-wrapper .desc-wrapper .icon {
    width: 20%;
    display: none;
  }
  .app-market-info-wrapper .desc-wrapper .title {
    width: 100%;
  }
  .app-market-info-wrapper .btn-wrapper {
    flex-wrap: wrap;
  }
  .contract-alert-modal-wrapper {
    width: 90% !important;
  }
}
